import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFieldArray } from 'react-hook-form';
import { fetchFileUpload } from 'api/auth';
import { fetchCreatePortfolio } from 'api/portfolio';
import SetErrorBar from 'utils/SetErrorBar';

interface Props {
  control: any;
  values: any;
  setValue: (e: any, v: any) => void;
}

export const WorkRegistViewModel = ({ control, values, setValue }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [img, setImg] = useState<any>([]);
  const [skillArr, setSkillArr] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    fields: workFields,
    append: workAppend,
    remove: workRemove,
  } = useFieldArray({
    control,
    name: 'subWorkInput',
  });

  const handleAddWork = useCallback(() => {
    workAppend({
      sub_title: '',
      sub_content: '',
      sub_picture: [],
    });
  }, [workAppend]);

  // DatePicker default value null
  useEffect(() => {
    setValue('startdate', null);
    setValue('enddate', null);
  }, []);

  useEffect(() => {
    const arr = values.skill.split(',');
    setSkillArr(arr);
    setImg(values.files);
  }, [values.skill, values.files]);

  // 포트폴리오 등록 핸들러
  const handleSubmitForm = async () => {
    setIsLoading(true);
    let attachment: any = [];

    if (img) {
      for (let i = 0; i < img.length; i++) {
        const file = img[i][0];
        const fileUploadData = await fetchFileUpload(file);
        attachment.push(fileUploadData.data.result.id);
      }
    }

    let payload: any = {
      type: location?.state.type,
      title: values.title,
      position: values.position,
      skils: values.skill,
      started_at: values.startdate,
      end_at: values.enddate,
      outline_content: values.outline_content,
    };

    for (let i = 0; i < values.subWorkInput.length; i++) {
      let attachment2: any = [];

      if (values.subWorkInput[i].sub_picture.length > 0) {
        const file = values.subWorkInput[i]?.sub_picture[0];
        const fileUploadData = await fetchFileUpload(file);
        attachment2.push(fileUploadData.data.result.id);
      }
      if (values.subWorkInput[i]) {
        payload = {
          ...payload,
          [`sub_title_${i + 1}`]: values.subWorkInput[i].sub_title,
          [`sub_content_${i + 1}`]: values.subWorkInput[i].sub_content,
        };
        if (attachment2.length > 0) {
          payload = {
            ...payload,
            [`sub_picture_${i + 1}`]: attachment2,
          };
        }
      }
    }

    if (attachment) {
      payload = {
        ...payload,
        attachments: attachment,
      };
    }

    const createData = await fetchCreatePortfolio(payload);

    if (createData.status === 200) {
      setIsLoading(false);
      navigate(`/works?type=${location?.state.type}`, {
        state: {
          type: location?.state.type,
        },
      });
    } else {
      setIsLoading(false);
      SetErrorBar('관리자 승인 후 등록 할 수 있습니다..');
    }
  };

  return {
    img,
    workFields,
    isLoading,
    workRemove,
    handleAddWork,
    handleSubmitForm,
  };
};
