import request from './request';

// 채팅 리스트 조회
const privateChatList = async () => {
  try {
    const data = await request(
      '/private-chats',
      'get',
      { 'content-type': 'application/json' },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 상대와의 채팅 정보 조회
const privateChatInfo = async (userId: string) => {
  try {
    const data = await request(
      `/private-chats/${userId}`,
      'get',
      { 'content-type': 'application/json' },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 채팅방 나가기
const exitChatRoom = async (chatRoomId: number) => {
  try {
    const data = await request(
      `/private-chats/${chatRoomId}/exit`,
      'delete',
      { 'content-type': 'application/json' },
      {
        is_chat_room_exit: true,
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  privateChatList,
  privateChatInfo,
  exitChatRoom,
};
