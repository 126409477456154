import request from './request';
import {
  PortfolioDetailResult,
  PortfolioResult,
  SuccessResult,
} from 'utils/ResultTypes';
import { PortfolioListPayloadProps } from 'utils/Types';

// 포트폴리오 리스트
export const fetchPortfolioList = async (
  payload: PortfolioListPayloadProps
): Promise<PortfolioResult> => {
  try {
    const result = request('/portfolio', 'get', {}, payload);

    return result;
  } catch (error: any) {
    return error;
  }
};

// 포트폴리오 상세
export const fetchPortfolioDetail = async (
  portfolioId: string
): Promise<PortfolioDetailResult> => {
  try {
    const result = await request(`/portfolio/${portfolioId}`, 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 포트폴리오 등록
export const fetchCreatePortfolio = async (
  payload: any
): Promise<SuccessResult> => {
  try {
    const result = await request('/portfolio', 'post', {}, payload);

    return result;
  } catch (error: any) {
    return error;
  }
};

// 포트폴리오 수정
export const fetchUpdatePortfolio = async (
  id: string,
  payload: any
): Promise<SuccessResult> => {
  try {
    const result = await request(`/portfolio/${id}`, 'put', {}, payload);

    return result;
  } catch (error: any) {
    return error;
  }
};

// 포트폴리오 삭제
export const fetchDeletePortfolio = async (portfolioId: string) => {
  try {
    const result = await request(`/portfolio/${portfolioId}`, 'delete', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};
