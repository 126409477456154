import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./translation.en.json";
import translationKO from "./translation.ko.json";

export const defaultNS = "translations";

const options = {
  order: [
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  lookupLocalStorage: "lng",
  convertDetectedLanguage: (lng) => lng.split("-")[0],
};

const resources = {
  en: {
    translations: translationEN,
  },
  ko: {
    translations: translationKO,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lan: "en",
    fallbackLng: "en",
    detection: options,
    //debug: true, // 로그
    defaultNS: defaultNS,
    ns: defaultNS,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
