import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import Layout from 'layout/Layout';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import FileInput3 from 'components/FileInput/FileInput3';
import FileUpload from 'components/FileUpload/FileUpload';
import ErrorsMessage from 'components/ErrorsMessage/ErrorsMessage';
import SetErrorBar from 'utils/SetErrorBar';
import registSchma from 'utils/validation/registSchma';
import IconPlusSVG from 'assets/images/icon_plus.svg';
import { WorksIFormProps } from 'utils/Types';
import 'react-datepicker/dist/react-datepicker.css';
import { WorkRegistViewModel } from 'services/WorkRegistViewModel';
import DimmedSpiner from 'utils/DimmedSpiner';

const defaultValues: WorksIFormProps = {
  title: '',
  position: '',
  skill: '',
  startdate: new Date(),
  enddate: new Date(),
  outline_content: '',
  subWorkInput: [
    {
      sub_title: '',
      sub_content: '',
      sub_picture: [],
    },
  ],
  sub_attachment_5: [],
  files: [],
};

const WorkRegist = () => {
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<WorksIFormProps>({
    defaultValues,
    resolver: yupResolver(registSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();
  const model = WorkRegistViewModel({ control, values, setValue });

  return (
    <Layout>
      {model?.isLoading && <DimmedSpiner />}
      <WorkRegistStyled>
        <div className='regist-inner'>
          <h2>프로젝트 등록</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  model?.handleSubmitForm();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='title'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='프로젝트 명'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 포지션'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='skill'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 스킬'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='datepicker-sec'>
              <Controller
                control={control}
                name='startdate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker datepicker1'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => {
                      field.onChange(date);
                    }}
                    placeholderText='시작일'
                  />
                )}
              />
              <span></span>
              <Controller
                control={control}
                name='enddate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => field.onChange(date)}
                    placeholderText='마감일'
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='outline_content'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    fullWidth={false}
                    multiline={true}
                    useMaxLengthIndicator={true}
                    maxLength={1000}
                    placeholder='개요 내용'
                    errors={errors}
                  />
                )}
              />
            </div>
            <BorderLine />
            {model?.workFields.map((work, index) => {
              return (
                <div key={work.id}>
                  <div className='input-sec'>
                    <Controller
                      control={control}
                      name={`subWorkInput.${index}.sub_title`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type='text'
                          multiline={false}
                          fullWidth={false}
                          placeholder={`${index + 1}. 소제목`}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                  <div className='input-sec2'>
                    <Controller
                      control={control}
                      name={`subWorkInput.${index}.sub_content`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type='text'
                          fullWidth={false}
                          multiline={true}
                          useMaxLengthIndicator={true}
                          maxLength={1000}
                          placeholder={`${index + 1}. 소제목 - 프로젝트 내용`}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                  <div className='input-sec2'>
                    <Controller
                      control={control}
                      name={`subWorkInput.${index}.sub_picture`}
                      render={({ field }) => (
                        <FileInput3
                          {...field}
                          w={isMobileWidth ? '260' : ''}
                          idx={index}
                          accept='.jpg, .png'
                          errors={errors}
                          fullWidth
                          placeholder={'(JPG(JPEG), PNG / 최대 5MB까지)'}
                        />
                      )}
                    />
                  </div>
                  <div className='section-add'>
                    {model?.workFields.length > 1 && (
                      <button
                        type='button'
                        className='delete-sec'
                        onClick={() => model?.workRemove(index)}
                      >
                        삭제
                      </button>
                    )}
                    <button
                      type='button'
                      className='add-sec'
                      onClick={() => {
                        if (model?.workFields.length >= 5)
                          SetErrorBar('최대 5개까지만 추가 가능합니다.');
                        else model?.handleAddWork();
                      }}
                    >
                      내용 추가 <img src={IconPlusSVG} alt='내용 추가 버튼' />
                    </button>
                  </div>
                  <BorderLine />
                </div>
              );
            })}
            <div className='sub-title'>
              첨부파일<span>(최대 7개까지 등록 가능합니다.)</span>
            </div>
            <div className='file-upload-wrap'>
              <Controller
                control={control}
                name='files'
                render={({ field }) => (
                  <FileUpload
                    img={field.value}
                    setImg={(e: any) => field.onChange(e)}
                    buttonStyle={uploadButton}
                    icon={'ic-plus'}
                    limit={7}
                    disabledPreview={false}
                  />
                )}
              />
            </div>
            {errors.files && <ErrorsMessage errors={errors} name='files' />}
            <Button variant='' preset={0} type='submit' fullWidth>
              등록하기
            </Button>
          </form>
        </div>
      </WorkRegistStyled>
    </Layout>
  );
};

const WorkRegistStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 40px 20px 60px 20px;
  overflow-y: scroll;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  & .regist-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .mo-form-wrap {
    width: 100%;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .input-sec2 {
    margin: 4px 0;
  }

  & .datepicker-sec {
    display: flex;
    margin: 10px 0;
    span {
      width: 20px;
    }
  }

  & .input-datepicker {
    width: 100%;
    height: 65px;
    padding-left: 20px;
    font-size: 16px;
    border: solid 1px #ddd;

    &::placeholder {
      color: #b2b2b2;
    }
  }

  & .sub-title {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #222;
    margin-top: 15px;

    span {
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #999;
      margin-left: 4px;
    }
  }

  & .file-upload-wrap {
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .section-add {
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
    margin-bottom: 15px;

    button {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    & .delete-sec {
      padding: 8px 12px;
      border: solid 1px red;
      background-color: #fff;
      color: red;
    }

    & .add-sec {
      padding: 8px 14px;
      margin-left: 6px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }
`;

const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 3px solid #1f1852;
  padding-bottom: 20px;
`;

const uploadButton = {
  width: '100px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20 0',
  borderRadius: '12px',
  border: 'solid 1px #000',
  backgroundColor: '#fff',
  marginRight: '20px',
};

export default WorkRegist;
