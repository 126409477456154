export enum STORAGE_ENUM {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  ID = "id",
}

export enum GET_PARAMS_ENUM {
  KEY = "key",
  TYPE = "type",
}

export enum SignTabTypeEnum {
  EMAILABLED,
  EMAILDISABLED,
  VERIFYABLED,
  VERIFYDISABLED,
}

export enum FriendTabTypeEnum {
  DEFAULT,
  REQUEST,
}

export enum SideBarTabTypeEnum {
  DEFAULT,
  REACT,
  NODE,
  RESEARCH,
  RESUME,
  FRIENDS,
  PYTHON,
  JAVA,
}

export enum AlertCasesEnum {
  CHAT_MESSAGE = "채팅 메시지",
  REQUEST_FRIEND = "친구요청",
  REJECT_FRIEND = "친구요청 거절",
  ACCEPT = "친구요청 수락",
}

export enum ChatTitleEnum {
  C = "c",
  F = "f",
}

export enum SOCKET_EVENT {
  CONNECT = "connect",
  SUCCESS = "success",
  STATE = "state",
  CHAT_MESSAGE = "chat_message",
  CHAT_JOIN = "/chats/join",
}
