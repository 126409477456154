import Slider from 'react-slick';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from 'layout/Layout';
import SetErrorBar from 'utils/SetErrorBar';
import { WorkDetailViewModel } from 'services/WorkDetailViewModel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import dayjs from 'dayjs';
import { useLang } from 'utils/lang/useLang';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const rgx = ['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG'];

const WorkDetail = () => {
  const lang = useLang();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const permission = useSelector((s: any) => s.auth.create_permission);
  const model = WorkDetailViewModel(
    location?.state.portfolioId,
    location?.state?.type
  );

  return (
    <Layout>
      {isMobileWidth ? (
        <MoWorkDetailStyled>
          <div className='work-header'>
            <div></div>
            {model?.accessToken && (
              <div className='btn-group'>
                <div className='registBtn'>
                  <button
                    onClick={() => {
                      if (permission) {
                        navigate(
                          `/work-edit/${location?.state.portfolioId}?type=${location?.state?.type}`,
                          {
                            state: {
                              data: model?.resultData,
                              arrayData: model?.subContentsData,
                            },
                          }
                        );
                      } else {
                        SetErrorBar(lang.message.approvalModified);
                      }
                    }}
                  >
                    {lang.common.edit}
                  </button>
                </div>
                <div className='registBtn deleteBtn'>
                  <button
                    onClick={() => {
                      if (permission) {
                        const result = window.confirm(lang.message.deletePost);
                        if (result) {
                          model?.handleDeletePortfolio();
                        }
                      } else {
                        SetErrorBar(lang.message.approvalDeleted);
                      }
                    }}
                  >
                    {lang.common.delete}
                  </button>
                </div>
              </div>
            )}
          </div>

          <h2>
            {model?.resultData?.raw?.type !== 'RESEARCH'
              ? lang.project.projectName
              : null}
            {model?.resultData?.raw?.title}
          </h2>
          <div className='work-detail-skils'>
            <span>{`#${dayjs(model?.resultData?.raw?.started_at).format(
              'YYYY-MM-DD'
            )} - ${dayjs(model?.resultData?.raw?.end_at).format(
              'YYYY-MM-DD'
            )}`}</span>
          </div>
          <div className='work-detail-skils'>
            <span>{`#${model?.resultData?.raw?.position}`}</span>
          </div>
          <div className='work-detail-skils'>
            {model?.resultData?.raw?.skils
              .split(', ')
              .map((e: string, idx: number) => {
                return <span key={idx}>{`#${e}`}</span>;
              })}
          </div>
          <BorderLine />
          <div className='work-detail-content'>
            <h3>{lang.project.outline}</h3>
            <div>
              {model?.resultData?.raw?.outline_content
                .split('\n')
                .map((line: any, i: number) => {
                  return (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  );
                })}
            </div>
          </div>
          <BorderLine />
          <div className='work-detail-content'>
            <h3>{lang.project.contents}</h3>
            <div>
              {model?.subContentsData?.length > 0 &&
                model?.subContentsData?.map((sub: any, idx: number) => {
                  return (
                    <div className='content-wrap' key={idx}>
                      <h4>{sub.sub_title}</h4>
                      <p>
                        {sub?.sub_content
                          .split('\n')
                          .map((line: any, i: number) => {
                            return (
                              <span key={i}>
                                {line}
                                <br />
                              </span>
                            );
                          })}
                      </p>
                      {sub?.sub_picture && (
                        <img
                          style={{ width: '100%' }}
                          src={`${process.env.REACT_APP_API}/files/${sub?.sub_picture?.id}`}
                          alt='이미지'
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <BorderLine />
          <div className='work-detail-attachment'>
            <h3>{lang.project.projectImage}</h3>
            <div>
              <Slider {...settings}>
                {model?.resultData?.raw?.attachments &&
                  model?.resultData?.attach_ments_info?.map(
                    (x: any, i: number) => {
                      if (rgx.includes(x.content_type.split('/')[1])) {
                        return (
                          <div key={i}>
                            <div className='attachment-img'>
                              <img
                                src={`${process.env.REACT_APP_API}/files/${x.id}`}
                                alt='이미지'
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </Slider>
            </div>
          </div>
          <div className='work-detail-attachment2'>
            <BorderLine />
            {model?.resultData?.raw?.attachments &&
              model?.resultData?.attach_ments_info?.map((x: any, i: number) => {
                if (!rgx.includes(x.content_type.split('/')[1])) {
                  return (
                    <div key={i}>
                      <div className='attachment-img'>
                        <video
                          width='100%'
                          controls
                          controlsList='nodownload'
                          src={`${process.env.REACT_APP_API}/files/${x.id}`}
                        ></video>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </MoWorkDetailStyled>
      ) : (
        <WorkDetailStyled>
          <div>
            <div className='work-header'>
              <div></div>
              {model?.accessToken && (
                <div className='btn-group'>
                  <div className='registBtn'>
                    <button
                      onClick={() => {
                        if (permission) {
                          navigate(
                            `/work-edit/${location?.state.portfolioId}?type=${location?.state?.type}`,
                            {
                              state: {
                                data: model?.resultData,
                                arrayData: model?.subContentsData,
                              },
                            }
                          );
                        } else {
                          SetErrorBar(lang.message.approvalModified);
                        }
                      }}
                    >
                      {lang.common.edit}
                    </button>
                  </div>
                  <div className='registBtn deleteBtn'>
                    <button
                      onClick={() => {
                        if (permission) {
                          const result = window.confirm(
                            lang.message.deletePost
                          );
                          if (result) {
                            model?.handleDeletePortfolio();
                          }
                        } else {
                          SetErrorBar(lang.message.approvalDeleted);
                        }
                      }}
                    >
                      {lang.common.delete}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <h2>
              {model?.resultData?.raw?.type !== 'RESEARCH'
                ? lang.project.projectName
                : null}
              {model?.resultData?.raw?.title}
            </h2>
            <div className='work-detail-skils'>
              <span>{`#${dayjs(model?.resultData?.raw?.started_at).format(
                'YYYY-MM-DD'
              )} - ${dayjs(model?.resultData?.raw?.end_at).format(
                'YYYY-MM-DD'
              )}`}</span>
            </div>
            <div className='work-detail-skils'>
              <span>{`#${model?.resultData?.raw?.position}`}</span>
            </div>
            <div className='work-detail-skils'>
              {model?.resultData?.raw?.skils
                .split(', ')
                .map((e: string, idx: number) => {
                  return <span key={idx}>{`#${e}`}</span>;
                })}
            </div>

            <BorderLine />
            <div className='work-detail-content'>
              <h3>{lang.project.outline}</h3>
              <div>
                {model?.resultData?.raw?.outline_content
                  .split('\n')
                  .map((line: any, i: number) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
              </div>
            </div>
            <BorderLine />
            <div className='work-detail-content'>
              <h3>{lang.project.contents}</h3>
              <div>
                {model?.subContentsData?.length > 0 &&
                  model?.subContentsData?.map((sub: any, idx: number) => {
                    return (
                      <div className='content-wrap' key={idx}>
                        <h4>{sub.sub_title}</h4>
                        <p>
                          {sub?.sub_content
                            .split('\n')
                            .map((line: any, i: number) => {
                              return (
                                <span key={i}>
                                  {line}
                                  <br />
                                </span>
                              );
                            })}
                        </p>
                        {sub?.sub_picture && (
                          <img
                            style={{ width: '100%' }}
                            src={`${process.env.REACT_APP_API}/files/${sub?.sub_picture?.id}`}
                            alt='이미지'
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <BorderLine />
            <div className='work-detail-attachment'>
              <h3>{lang.project.projectImage}</h3>
              <div>
                <Slider {...settings}>
                  {model?.resultData?.raw?.attachments &&
                    model?.resultData?.attach_ments_info?.map(
                      (x: any, i: number) => {
                        if (rgx.includes(x.content_type.split('/')[1])) {
                          return (
                            <div key={i}>
                              <div className='attachment-img'>
                                <img
                                  src={`${process.env.REACT_APP_API}/files/${x.id}`}
                                  alt='이미지'
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    )}
                </Slider>
              </div>
            </div>
            <div className='work-detail-attachment2'>
              <BorderLine />
              {model?.resultData?.raw?.attachments &&
                model?.resultData?.attach_ments_info?.map(
                  (x: any, i: number) => {
                    if (!rgx.includes(x.content_type.split('/')[1])) {
                      return (
                        <div key={i}>
                          <div className='attachment-img'>
                            <video
                              width='100%'
                              controls
                              controlsList='nodownload'
                              src={`${process.env.REACT_APP_API}/files/${x.id}`}
                            ></video>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
            </div>
          </div>
        </WorkDetailStyled>
      )}
    </Layout>
  );
};

const WorkDetailStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 20px 0 50px 0;
  overflow-y: scroll;

  & > div {
    width: 800px;
    margin: 0 auto;
  }

  .work-header {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .work-detail-skils {
    margin-bottom: 30px;
    span {
      margin-right: 10px;
      font-style: italic;
      text-decoration: underline;
    }
  }

  .work-detail-content {
    margin-bottom: 30px;
  }

  .content-wrap {
    margin-bottom: 20px;

    p {
      margin-bottom: 20px;
    }

    img {
      width: calc(100% - 20px);
    }
  }

  .work-detail-attachment {
    width: calc(100% - 20px);
    padding-bottom: 20px;
    & > div {
      margin: 0 auto;
    }

    .attachment-img {
      display: flex;
      justify-content: center;
      img {
        height: 400px;
      }
    }
  }
  .work-detail-attachment2 {
    width: calc(100% - 20px);
    margin-top: 30px;

    & > div {
      margin: 0 auto;
    }

    .attachment-img {
      display: flex;
      justify-content: center;
      img {
        height: 400px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: #1f1852;
  }
  .slick-dots li.slick-active button:before {
    color: #1f1852 !important;
  }

  .btn-group {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;

    .registBtn {
      display: flex;
      margin-right: 15px;

      button {
        cursor: pointer;
        width: 80px;
        height: 35px;
        color: #fff;
        border-radius: 4px;
        background-color: #1f1852;
      }
    }

    .deleteBtn button {
      background-color: #e40000;
    }
  }
`;

const MoWorkDetailStyled = styled.div`
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 20px 1rem 50px 1rem;
  overflow-y: scroll;

  .work-header {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .work-detail-skils {
    margin-bottom: 30px;
    span {
      margin-right: 10px;
      font-style: italic;
      text-decoration: underline;
    }
  }

  .work-detail-content {
    margin-bottom: 30px;
  }

  .content-wrap {
    margin-bottom: 20px;

    p {
      margin-bottom: 20px;
    }

    img {
      width: calc(100% - 20px);
    }
  }

  .work-detail-attachment {
    width: calc(100% - 20px);
    padding-bottom: 20px;

    & > div {
      width: calc(100% - 50px);
      margin: 0 auto;
    }

    .attachment-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }
  .work-detail-attachment2 {
    width: calc(100% - 20px);
    margin-top: 30px;

    & > div {
      margin: 0 auto;
    }

    .attachment-img {
      display: flex;
      justify-content: center;
      img {
        height: 400px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: #1f1852;
  }
  .slick-dots li.slick-active button:before {
    color: #1f1852 !important;
  }

  .btn-group {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;

    .registBtn {
      display: flex;
      margin-right: 15px;

      button {
        cursor: pointer;
        width: 80px;
        height: 35px;
        color: #fff;
        border-radius: 4px;
        background-color: #1f1852;
      }
    }

    .deleteBtn button {
      background-color: #e40000;
    }
  }
`;

const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 3px solid #1f1852;
  padding-bottom: 20px;
`;

export default WorkDetail;
