import axios, { AxiosRequestConfig } from "axios";

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API,
};

const instance = axios.create(axiosConfig);

instance.interceptors.request.use(
  async (req) => {
    const targetStorage = localStorage.getItem("accessToken")
      ? localStorage
      : sessionStorage;
    const accessToken = targetStorage.getItem("accessToken");
    // @ts-ignore
    if (accessToken) req.headers.authorization = `Bearer ${accessToken}`;

    return req;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

export default instance;
