import { fetchChangePassword } from 'api/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_PARAMS_ENUM } from 'utils/Enum';
import { useLang } from 'utils/lang/useLang';
import SetErrorBar from 'utils/SetErrorBar';

export const ChangeAuthViewModel = (values: any) => {
  const { message } = useLang();
  const navigate = useNavigate();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  const key: any = params.get(GET_PARAMS_ENUM.KEY);

  useEffect(() => {
    if (values.changepw !== '') {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.changepw]);

  // 비밀번호 변경 핸들러
  const handleChangeAuth = async () => {
    const changePassword = await fetchChangePassword(key, values.changepw);

    if (changePassword.status === 200) {
      SetErrorBar(message.isChangedPassword);
      navigate('/login', { replace: true });
    }
  };

  return { isAbledBtn, handleChangeAuth };
};
