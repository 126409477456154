import { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUiValue } from "store/ui";
import { setIsChat } from "store/chat";
import { setIsAlert } from "store/alert";
import { setIsMypage } from "store/auth";
import { logout } from "api/auth";
import Chat from "pages/Chat/Chat";
import MypageMenu from "components/MypageMenu/MypageMenu";
import AlertMessage from "components/AlertMessage/AlertMessage";
import Logo from "assets/images/logo.png";
import languagePng from "assets/images/language.png";
import message from "assets/images/messenger.png";
import alert from "assets/images/notification.png";
import alertnoti from "assets/images/notification-alert.png";
import menu from "assets/images/menu.png";
import close from "assets/images/close.png";
import { HeaderViewModel } from "services/HeaderViewModel";
import { useLang } from "utils/lang/useLang";
import { useChangeLanguage } from "hooks/useChangeLanguage";

const Header = () => {
  const lang = useLang();
  const { isLang, handleChangeLanguage } = useChangeLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useSelector((s: any) => s.auth);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const { isChat } = useSelector((s: any) => s.chat);
  const { isAlert } = useSelector((s: any) => s.alert);
  const { isMypage } = useSelector((s: any) => s.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const model = HeaderViewModel();

  return (
    <>
      {isMobileWidth ? (
        <HeaderStyled>
          <h1
            onClick={() => {
              navigate("/");
              dispatch(setUiValue("Home"));
            }}
          >
            <img src={Logo} alt="logo_image" />
          </h1>
          <div className="mo-wrap">
            {model?.accessToken && (
              <ul className="mo-icon">
                <li
                  onClick={() => {
                    if (isChat) {
                      dispatch(setIsChat(false));
                    } else {
                      dispatch(setIsChat(true));
                    }
                    if (isAlert) dispatch(setIsAlert(false));
                    if (isMypage) dispatch(setIsMypage(false));
                  }}
                >
                  <img src={message} alt="chat_logo_image" />
                </li>
                <li
                  className="alertImg"
                  onClick={() => {
                    if (isAlert) {
                      dispatch(setIsAlert(false));
                    } else {
                      dispatch(setIsAlert(true));
                    }
                    if (isChat) dispatch(setIsChat(false));
                    if (isMypage) dispatch(setIsMypage(false));
                  }}
                >
                  <img
                    src={
                      model?.unreadAlerts.includes(false) ? alertnoti : alert
                    }
                    alt="alert_logo_image"
                  />
                </li>
              </ul>
            )}
            <div
              className="menu-icon"
              style={{ marginRight: "30px" }}
              onClick={() => {
                dispatch(setIsMypage(false));
                handleChangeLanguage();
              }}
            >
              <img src={languagePng} alt="languagePng_logo_image" />
            </div>
            <div className="menu-icon" onClick={() => setIsMenuOpen(true)}>
              <img src={menu} alt="menu_image" />
            </div>
          </div>
          {model?.accessToken
            ? isMenuOpen && (
                <div className="mo-signedlist">
                  <div className="mo-inner1">
                    {isLang === "en" ? (
                      <h3>
                        {lang.auth.welcome}
                        <span>{name}</span>
                      </h3>
                    ) : (
                      <h3>
                        <span>{name}</span>
                        {lang.auth.welcome}
                      </h3>
                    )}
                    <div onClick={() => setIsMenuOpen(false)}>
                      <img src={close} alt="menu_close_image" />
                    </div>
                  </div>
                  <ul className="mo-menulist">
                    <li
                      onClick={() => {
                        navigate("/works?type=REACT", {
                          state: {
                            type: "REACT",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      React&React-native
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=NODE", {
                          state: {
                            type: "NODE",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Node.JS
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=JAVA", {
                          state: {
                            type: "JAVA",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Java
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=Python", {
                          state: {
                            type: "Python",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Python
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=RESEARCH", {
                          state: {
                            type: "RESEARCH",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Research
                    </li>
                    <li
                      onClick={() => {
                        navigate("/resume");
                        dispatch(setUiValue("Resume"));
                      }}
                    >
                      Resume
                    </li>
                    <li
                      onClick={() => {
                        navigate("/friends");
                        dispatch(setUiValue("Friends"));
                      }}
                    >
                      Friends
                    </li>
                    <li
                      onClick={() => {
                        navigate("/mypage");
                      }}
                    >
                      {lang.auth.editUserInfo}
                    </li>
                    <li
                      onClick={() => {
                        navigate("/adminpage", {
                          state: {
                            isUpdate: false,
                          },
                        });
                      }}
                    >
                      {lang.auth.adminPage}
                    </li>
                  </ul>
                  <div
                    className="mo-logout"
                    onClick={() => {
                      logout();
                      navigate("/login", { replace: true });
                    }}
                  >
                    {lang.auth.logout}
                  </div>
                </div>
              )
            : isMenuOpen && (
                <div className="mo-signedlist">
                  <div className="mo-inner1">
                    <ul className="mo-join">
                      <li
                        onClick={() => {
                          navigate("/login");
                          dispatch(setUiValue("Login"));
                        }}
                      >
                        {lang.auth.login}
                      </li>
                      <li
                        onClick={() => {
                          navigate("/sign");
                          dispatch(setUiValue("Sign"));
                        }}
                      >
                        {lang.auth.signup}
                      </li>
                    </ul>
                    <div onClick={() => setIsMenuOpen(false)}>
                      <img src={close} alt="menu_close_image" />
                    </div>
                  </div>
                  <ul className="mo-menulist">
                    <li
                      onClick={() => {
                        navigate("/works?type=REACT", {
                          state: {
                            type: "REACT",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      React&React-native
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=NODE", {
                          state: {
                            type: "NODE",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Node.JS
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=JAVA", {
                          state: {
                            type: "JAVA",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Java
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=PYTHON", {
                          state: {
                            type: "PYTHON",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Python
                    </li>
                    <li
                      onClick={() => {
                        navigate("/works?type=RESEARCH", {
                          state: {
                            type: "RESEARCH",
                          },
                        });
                        dispatch(setUiValue("Works"));
                      }}
                    >
                      Research
                    </li>
                    <li
                      onClick={() => {
                        navigate("/resume");
                        dispatch(setUiValue("Resume"));
                      }}
                    >
                      Resume
                    </li>
                    <li
                      onClick={() => {
                        navigate("/friends");
                        dispatch(setUiValue("Friends"));
                      }}
                    >
                      Friends
                    </li>
                  </ul>
                </div>
              )}
          {isChat && <Chat />}
          {isAlert && (
            <AlertMessage
              handleAlerts={model?.handleAlerts}
              alertsData={model?.alertsData}
            />
          )}
        </HeaderStyled>
      ) : (
        <HeaderStyled>
          <h1
            onClick={() => {
              navigate("/");
              dispatch(setUiValue("Home"));
            }}
          >
            <img src={Logo} alt="logo_image" />
          </h1>
          {model?.accessToken ? (
            <ul className="signedlist">
              <li
                onClick={() => {
                  dispatch(setIsMypage(false));
                  handleChangeLanguage();
                }}
              >
                <img src={languagePng} alt="languagePng_logo_image" />
              </li>
              <li
                onClick={() => {
                  if (isChat) {
                    dispatch(setIsChat(false));
                  } else {
                    dispatch(setIsChat(true));
                  }
                  if (isAlert) dispatch(setIsAlert(false));
                  if (isMypage) dispatch(setIsMypage(false));
                }}
              >
                <img src={message} alt="chat_logo_image" />
              </li>
              <li
                className="alertImg"
                onClick={() => {
                  if (isAlert) {
                    dispatch(setIsAlert(false));
                  } else {
                    dispatch(setIsAlert(true));
                  }
                  if (isChat) dispatch(setIsChat(false));
                  if (isMypage) dispatch(setIsMypage(false));
                }}
              >
                <img
                  src={model?.unreadAlerts.includes(false) ? alertnoti : alert}
                  alt="alert_logo_image"
                />
              </li>

              <li
                onClick={() => {
                  if (isMypage) {
                    dispatch(setIsMypage(false));
                  } else {
                    dispatch(setIsMypage(true));
                  }
                  if (isChat) dispatch(setIsChat(false));
                  if (isAlert) dispatch(setIsAlert(false));
                }}
              >
                {isLang === "en" ? (
                  <div>
                    {lang.auth.welcome}
                    <span>{name}</span>
                  </div>
                ) : (
                  <div>
                    <span>{name}</span>
                    {lang.auth.welcome}
                  </div>
                )}
              </li>
              <li
                onClick={() => {
                  logout();
                  navigate("/login", { replace: true });
                }}
              >
                {lang.auth.logout}
              </li>
            </ul>
          ) : (
            <ul className="signlist">
              <li
                onClick={() => {
                  dispatch(setIsMypage(false));
                  handleChangeLanguage();
                }}
              >
                <img src={languagePng} alt="languagePng_logo_image" />
              </li>
              <li
                onClick={() => {
                  navigate("/login");
                  dispatch(setUiValue("Login"));
                }}
              >
                {lang.auth.login}
              </li>
              <li
                onClick={() => {
                  navigate("/sign");
                  dispatch(setUiValue("Sign"));
                }}
              >
                {lang.auth.signup}
              </li>
            </ul>
          )}
          {isChat && <Chat />}
          {isAlert && (
            <AlertMessage
              alertsData={model?.alertsData}
              handleAlerts={model?.handleAlerts}
            />
          )}
          {isMypage && <MypageMenu />}
        </HeaderStyled>
      )}
    </>
  );
};

const HeaderStyled = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 3px solid #d9d9d9;
  h1 {
    cursor: pointer;
  }
  h1 img {
    width: 1.5em;
  }
  nav {
    width: 30em;
    margin-left: 10em;
    ul {
      display: flex;
      & li {
        cursor: pointer;
      }
    }
  }
  .menu-icon img {
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .menulist {
    justify-content: space-around;
  }
  .signlist {
    width: 10em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & li {
      cursor: pointer;
    }
    img {
      display: block;
      width: 21px;
      height: 21px;
    }
  }
  .signedlist {
    width: 25em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & li {
      cursor: pointer;
    }
    img {
      display: block;
      width: 21px;
      height: 21px;
    }
    span {
      font-weight: bold;
    }
  }
  .mo-signedlist {
    position: absolute;
    z-index: 99999999999;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;

    img {
      display: block;
      width: 21px;
      height: 21px;
    }
    span {
      font-weight: bold;
    }
  }

  .mo-wrap {
    display: flex;
  }

  .mo-inner1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #d9d9d9;
    h3 {
      margin-top: 5px;
    }
    img {
      cursor: pointer;
    }
  }

  .mo-menulist {
    padding: 20px 30px;

    li {
      font-size: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

  .alertImg img {
    width: 25px;
    height: 25px;
  }

  .mo-join {
    display: flex;
    margin-top: 5px;

    li {
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .mo-icon {
    display: flex;

    li {
      margin-right: 30px;
    }
    img {
      display: block;
      width: 24px;
      height: 24px;
    }
    .alertImg img {
      width: 27px;
      height: 27px;
    }
  }

  .mo-logout {
    border-top: 1px solid #d9d9d9;
    color: red;
    font-size: 20px;
    margin-top: 100px;
    padding: 10px 30px;
    cursor: pointer;
  }
`;

export default Header;
