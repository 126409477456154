import Modal, {
  ModalButtonWrap,
  ModalContainer,
  ModalText,
} from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { ITextModalProps } from 'utils/Types';
import { useLang } from 'utils/lang/useLang';

const TextModal = (props: ITextModalProps) => {
  const { common } = useLang();
  return (
    <Modal {...props}>
      <ModalContainer>
        <ModalText>{props?.content}</ModalText>
        <ModalButtonWrap>
          {props.onSubmit && (
            <Button
              variant=''
              preset={0}
              type='button'
              w={'100'}
              h={'48'}
              fSize={'14'}
              color='#555555'
              onClick={props.onClose}
            >
              {common.cancel}
            </Button>
          )}
          <Button
            variant=''
            preset={0}
            type='button'
            w={'100'}
            h={'48'}
            fSize={'14'}
            onClick={props?.onSubmit ? props.onSubmit : props.onClose}
          >
            {props?.submitText || common.confirm}
          </Button>
        </ModalButtonWrap>
      </ModalContainer>
    </Modal>
  );
};

export default TextModal;
