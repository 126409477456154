import { fetchDeleteFriend, fetchFriend, fetchPostFriend } from 'api/friends';
import { useEffect, useState } from 'react';
import { FriendTabTypeEnum } from 'utils/Enum';

export const FriendListViewModel = (
  data: any,
  mutate: any,
  requestsRefetch: any,
  setTabType: any,
  setSelectedFriendInfo: any
) => {
  const [selectFriendData, setSelectFriendData] = useState<any>(null);
  const [isApprovalModalOpen, setIsApprovalModalOpen] =
    useState<boolean>(false);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [confirmApproveModal, setConfirmApproveModal] =
    useState<boolean>(false);
  const [confirmDeleteMember, setConfirmDeleteMember] =
    useState<boolean>(false);
  const [confirmRefuseMember, setConfirmRefuseMember] =
    useState<boolean>(false);

  const getRequest =
    data?.is_available_approve_friend === true && data?.is_friend === false;
  const isFriend = selectFriendData?.is_friend;
  const isFriend_request =
    selectFriendData?.status === 'REQUEST' ? true : false;

  useEffect(() => {
    if (data !== '') handleFriend();
  }, [data]);

  const addHyphen = (v: any) => {
    return v?.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      '$1-$2-$3'
    );
  };

  // 친구 데이터
  const handleFriend = async () => {
    const friendData = await fetchFriend(data?.name);

    if (friendData) {
      setSelectFriendData(friendData.result[0]);
    }
  };

  // 친구 요청 승인
  const handleApproveFriend = async () => {
    setIsApprovalModalOpen(false);
    const postFriendData = await fetchPostFriend(data.id);

    if (postFriendData?.data?.result) {
      setConfirmApproveModal(true);
      setSelectedFriendInfo(null);
      setTimeout(() => {
        mutate();
        requestsRefetch();
        setTabType(FriendTabTypeEnum.DEFAULT);
      }, 250);
    } else {
      window.alert(postFriendData.response?.data.message);
      setIsApprovalModalOpen(false);
    }
  };

  // 친구 요청 거절
  const handleRefuseFriend = async () => {
    setIsRefuseModalOpen(false);
    const refuseData = await fetchDeleteFriend(data.id);

    if (refuseData) {
      setConfirmRefuseMember(true);
      setSelectedFriendInfo(null);
      setTimeout(() => {
        mutate();
        requestsRefetch();
        setTabType(FriendTabTypeEnum.DEFAULT);
      }, 250);
    } else {
      window.alert(refuseData.response?.data.message);
      setIsRefuseModalOpen(false);
    }
  };

  // 친구 삭제
  const handleDeleteFriend = async () => {
    setIsDeleteModalOpen(false);
    const deleteData = await fetchDeleteFriend(data.id);

    if (deleteData) {
      setConfirmDeleteMember(true);
      setSelectedFriendInfo(null);
      setTimeout(() => {
        mutate();
        requestsRefetch();
      }, 250);
    } else {
      window.alert('에러 발생..');
      setIsDeleteModalOpen(false);
    }
  };

  return {
    selectFriendData,
    getRequest,
    isFriend,
    isFriend_request,
    isRefuseModalOpen,
    isDeleteModalOpen,
    isApprovalModalOpen,
    confirmApproveModal,
    confirmDeleteMember,
    confirmRefuseMember,
    addHyphen,
    handleApproveFriend,
    handleRefuseFriend,
    handleDeleteFriend,
    setIsRefuseModalOpen,
    setIsDeleteModalOpen,
    setIsApprovalModalOpen,
    setConfirmApproveModal,
    setConfirmDeleteMember,
    setConfirmRefuseMember,
  };
};
