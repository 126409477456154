import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { setUiValue } from "store/ui";
import { GET_PARAMS_ENUM, SideBarTabTypeEnum } from "utils/Enum";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serchParams, setSearchParams] = useSearchParams();
  const queryString = serchParams.get(GET_PARAMS_ENUM.TYPE);
  const { currentPosition } = useSelector((s: any) => s.ui);
  const [tabType, setTabType] = useState<SideBarTabTypeEnum>(
    SideBarTabTypeEnum.DEFAULT
  );

  useEffect(() => {
    switch (queryString) {
      case "REACT":
        setTabType(SideBarTabTypeEnum.REACT);
        break;
      case "NODE":
        setTabType(SideBarTabTypeEnum.NODE);
        break;
      case "JAVA":
        setTabType(SideBarTabTypeEnum.JAVA);
        break;
      case "PYTHON":
        setTabType(SideBarTabTypeEnum.PYTHON);
        break;
      case "RESEARCH":
        setTabType(SideBarTabTypeEnum.RESEARCH);
        break;
      case "RESUME":
        setTabType(SideBarTabTypeEnum.RESUME);
        break;
      case "FRIENDS":
        setTabType(SideBarTabTypeEnum.FRIENDS);
        break;
      default:
        setTabType(SideBarTabTypeEnum.DEFAULT);
        break;
    }
  }, [location]);

  return (
    <SidebarStyled>
      <h2>{currentPosition}</h2>
      <ul className="sidebar-list">
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.REACT}
          onClick={() => {
            navigate("/works?type=REACT", {
              state: {
                type: "REACT",
              },
            });
            dispatch(setUiValue("Works"));
          }}
        >
          React&React-native
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.NODE}
          onClick={() => {
            navigate("/works?type=NODE", {
              state: {
                type: "NODE",
              },
            });
            dispatch(setUiValue("Works"));
          }}
        >
          Node.JS
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.JAVA}
          onClick={() => {
            navigate("/works?type=JAVA", {
              state: {
                type: "JAVA",
              },
            });
            dispatch(setUiValue("Works"));
          }}
        >
          Java
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.PYTHON}
          onClick={() => {
            navigate("/works?type=PYTHON", {
              state: {
                type: "PYTHON",
              },
            });
            dispatch(setUiValue("Works"));
          }}
        >
          Python
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.RESEARCH}
          onClick={() => {
            navigate("/works?type=RESEARCH", {
              state: {
                type: "RESEARCH",
              },
            });
            dispatch(setUiValue("Works"));
          }}
        >
          Research
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.RESUME}
          onClick={() => {
            navigate("/resume?type=RESUME");
            dispatch(setUiValue("Resume"));
          }}
        >
          Resume
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === SideBarTabTypeEnum.FRIENDS}
          onClick={() => {
            navigate("/friends?type=FRIENDS");
            dispatch(setUiValue("Friends"));
          }}
        >
          Friends
        </SideMenuStyled>
      </ul>
    </SidebarStyled>
  );
};

const SidebarStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: calc(100vh - 80px);
  border-right: 3px solid #d9d9d9;

  h2 {
    font-weight: bold;
    margin: 60px 0 10px 30px;
  }

  .sidebar-list {
    width: 80%;
    margin: 0 auto;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid #d9d9d9;
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #1f1852;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
`;
const SideMenuStyled = styled.li<{ active: boolean }>`
  ${({ active }) =>
    active
      ? css`
          color: #fff;
          background-color: #1f1852;
          border-bottom: 1px solid #d9d9d9;
        `
      : css`
          color: #000;
          background-color: #fff;
          border-bottom: 1px solid #d9d9d9;
        `}
`;

export default SideBar;
