import { createSlice } from "@reduxjs/toolkit";
import { AuthStateType } from "utils/Types";

export const initialState: AuthStateType = {
  name: "",
  userId: 0,
  principal: undefined,
  isMypage: false,
  permission: false,
  create_permission: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthValue: (state, action) => {
      state.name = action.payload;
    },
    setUserIdValue: (state, action) => {
      state.userId = action.payload;
    },
    setPrincipal: (state, action) => {
      state.principal = action.payload;
    },
    setIsMypage: (state, action) => {
      state.isMypage = action.payload;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    setCreatePermission: (state, action) => {
      state.create_permission = action.payload;
    },
  },
});

export const {
  setAuthValue,
  setUserIdValue,
  setPrincipal,
  setIsMypage,
  setPermission,
  setCreatePermission,
} = authSlice.actions;

export default authSlice.reducer;
