import styled from '@emotion/styled';

const DimmedSpiner = ({ height }: any) => {
  return (
    <DimmedSpinStyled height={height}>
      <div className='spin-container'>
        <div className='spin-wrap'>
          <div className='spinner'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </DimmedSpinStyled>
  );
};

export default DimmedSpiner;

export const DimmedSpinStyled = styled.div<{ height?: number }>`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .spin-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spin-wrap {
    height: ${(props) => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spin-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #fff;
  }
  .spinner {
    color: official;
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 100px;
  }
  .spinner div {
    transform-origin: 40px 50px;
    animation: ani-spinner 1.2s linear infinite;
  }
  .spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 13px;
    border-radius: 10px;
    background: #fff;
  }
  .spinner div:nth-of-type(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .spinner div:nth-of-type(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .spinner div:nth-of-type(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .spinner div:nth-of-type(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .spinner div:nth-of-type(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .spinner div:nth-of-type(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .spinner div:nth-of-type(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .spinner div:nth-of-type(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .spinner div:nth-of-type(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .spinner div:nth-of-type(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .spinner div:nth-of-type(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .spinner div:nth-of-type(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes ani-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
