import * as yup from 'yup';

export default yup.object().shape({
  resume: yup
    .mixed()
    .required('A file is required')
    .test('resume', '이력서를 첨부해주세요.', (value) => {
      return value && ['application/pdf'].includes(value[0]?.type);
    }),
});
