import { SuccessResult } from 'utils/ResultTypes';
import request from './request';

// 알림
export const fetchAlert = async () => {
  try {
    const result = await request('/alerts', 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 읽은 알림 처리
export const fetchUpdateAlert = async (
  alert_id: string
): Promise<SuccessResult> => {
  try {
    const result = await request(
      '/alerts/updates',
      'put',
      {},
      { alert_id, is_readed: true }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};
