/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const global = css`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-weight: 500;
    font-family: sans-serif;
    line-height: 1.5;
    text-decoration: none;
    box-sizing: border-box;
    list-style: none;
  }
`;

export default global;
