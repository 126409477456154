import { useEffect, useRef, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import io from 'socket.io-client';
import ChatApi from 'api/chat';
import PrivateChatApi from 'api/privateChat';
import TextModal from 'components/TextModal/TextModal';
import ChatFileUpload from 'components/FileUpload/ChatFileUpload';
import ProfilesImage from 'components/Profile/ProfilesImage';
import MenuPNG from 'assets/images/menu.png';
import repleSVG from 'assets/images/reple.svg';
import BackIcSVG from 'assets/images/back_ic.svg';
import IcSendSVG from 'assets/images/ic-send.svg';
import IcDelSVG from 'assets/images/del_ic.svg';
import IcSendDisabledSVG from 'assets/images/ic-send-disabled.svg';
import {
  ChatProps,
  SendMessagePayloadProps,
  ProfileImageProps,
} from 'utils/Types';
import { SOCKET_EVENT } from 'utils/Enum';
import { fetchFileUpload } from 'api/auth';
import { useDispatch } from 'react-redux';
import { setSelectedUserInfo } from 'store/chat';
import { useChangeLanguage } from 'hooks/useChangeLanguage';
import { useLang } from 'utils/lang/useLang';

const PrivateChat = ({
  messages,
  selectedUserInfo,
  setOpenPrivateChat,
  setOpenChat,
  userInfo,
  mutateMessages,
  handleprivateChatInfo,
  handlePrivateChatList,
  chatRoomId,
  selectedUserId,
}: ChatProps) => {
  const root = rootCss();
  const dispatch = useDispatch();
  const { chatting } = useLang();
  const { isLang } = useChangeLanguage();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [reple, setReple] = useState<any>();
  const [message, setMessage] = useState<string>('');
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [socketState, setSocketState] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const socketUrl: any = process.env.REACT_APP_API;
  const socket = io(socketUrl, {
    query: '',
    transports: ['websocket'],
    autoConnect: true,
  });

  // socket
  useEffect(() => {
    socket.on(SOCKET_EVENT.CONNECT, () => {
      socket.on(SOCKET_EVENT.SUCCESS, function () {
        console.log('Socket 접속 성공');
      });
      socket.on(SOCKET_EVENT.STATE, function (args: any) {
        console.log('참고 상태 : ', args);
      });
    });

    socket.on(SOCKET_EVENT.CHAT_MESSAGE, function (args: any) {
      mutateMessages();
      handlePrivateChatList();
    });

    setSocketState(socket);

    return () => {
      socket?.disconnect();
    };
  }, [chatRoomId]);

  useEffect(() => {
    if (socketState !== null) {
      socket.emit(SOCKET_EVENT.CHAT_JOIN, { id: chatRoomId });
    }
  }, [socketState, chatRoomId]);

  // Enter key handler
  const handleEnterEvent = (e: any) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key === 'Enter') handleSendMessage();
  };

  // 메세지 전송
  const handleSendMessage = async () => {
    if (message.length > 0) {
      const tempMessage = message;
      setMessage('');

      let payload: SendMessagePayloadProps = {
        type: 'TEXT',
        content: tempMessage,
        data: {},
        user_id: selectedUserId,
        parent_message_id: reple ? reple?.id : null,
        attachments: 0,
      };
      await ChatApi.sendMessage({
        chatRoomId: String(chatRoomId),
        payload,
      }).then((res) => {
        setReple('');
        mutateMessages(chatRoomId);
        handleprivateChatInfo(selectedUserId);
      });
    }
  };

  // 이미지 전송
  const handleSendImage = async (params: FileList) => {
    let imgAttachment: any = [];

    if (params.length > 0) {
      const imgfile = params[0];
      const fileUploadData = await fetchFileUpload(imgfile);
      imgAttachment.push(fileUploadData.data.result.id);
    }

    let payload: SendMessagePayloadProps = {
      type: 'TEXT',
      data: {},
      user_id: selectedUserId,
      parent_message_id: reple ? reple?.id : null,
    };

    if (imgAttachment) {
      payload = {
        ...payload,
        attachments: imgAttachment[0],
      };
    }
    await ChatApi.sendMessage({
      chatRoomId: String(chatRoomId),
      payload,
    }).then((res) => {
      mutateMessages(chatRoomId);
      handleprivateChatInfo(selectedUserId);
    });
  };

  // 채팅방 설정 가져오기
  const handleChatRoomConfig = async () => {
    await ChatApi.chatRoomConfig(String(chatRoomId))
      .then((res) => {
        setChecked(res.result.is_text_notification_enabled);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 채팅방 설정 변경하기
  const handleSetChatRoomConfig = async () => {
    let payload = { is_text_notification_enabled: !checked };
    await ChatApi.setChatRoomConfig({ chatRoomId: String(chatRoomId), payload })
      .then((res) => {
        if (res.data.result) {
          setChecked(!checked);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 채팅방 나가기
  const handleExitChatRoom = async () => {
    await PrivateChatApi.exitChatRoom(chatRoomId)
      .then((res: any) => {
        setOpenPrivateChat(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div css={root}>
      {!isMenu ? (
        <div className='root-inner'>
          <div className='top-nav-wrap'>
            <img
              src={BackIcSVG}
              alt='뒤로가기 아이콘'
              className='back-icon'
              onClick={() => {
                dispatch(setSelectedUserInfo(undefined));
                setOpenPrivateChat(false);
                setOpenChat(true);
                handlePrivateChatList();
              }}
            />
            <div className='chatroom-title'>
              {selectedUserInfo?.name} {selectedUserInfo?.position}
            </div>
            <img
              src={MenuPNG}
              alt='채팅 메뉴 아이콘'
              className='menu-icon'
              onClick={() => {
                setIsMenu(true);
                handleChatRoomConfig();
              }}
            />
          </div>
          <div className='line'></div>
          <div className='chat-content' ref={messageContainerRef}>
            {messages?.map((v: any, i: number) => {
              return v?.send_user?.id === userInfo?.id ? (
                <div key={i} ref={scrollRef} className='me-chat-wrap'>
                  <div className='chat-wrap'>
                    <div className='right-date-wrap'>
                      <div className='right-date-item'>
                        {dayjs(v?.created_at).format('YY.MM.DD')}
                      </div>
                      <div className='right-date-item'>
                        {dayjs(v?.created_at).format('HH:mm')}
                      </div>
                    </div>
                    {!v.parent_message ? (
                      <div className='me-chat-item'>
                        {v?.content ? (
                          v?.content
                        ) : (
                          <ImageMessage
                            fileId={v?.files?.id}
                            REACT_APP_API={process.env.REACT_APP_API}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='me-chat-item'>
                        <div className='reple-title'>
                          {isLang === 'en' && 'Reply to '}
                          {v?.parent_message?.send_user?.name}{' '}
                          {v?.parent_message?.send_user?.position}
                          {isLang === 'ko' && '에게 답장'}
                        </div>
                        <div className='reple-content'>
                          {v?.parent_message?.content}
                        </div>
                        <div className='reple-line'></div>
                        {v?.content}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div key={i} ref={scrollRef}>
                  <div className='character-wrap'>
                    <ProfilesImage
                      width={34}
                      height={34}
                      fileId={v?.send_user?.profile_image_id}
                      containerStyle={{
                        border: 'solid 1px #e3e3e3',
                        borderRadius: '100px',
                        marginRight: '10px',
                      }}
                    />
                    <div className='user-info-wrap'>
                      <div className='info-row-wrap'>
                        <div className='info-row-name'>
                          {v?.send_user?.name}
                        </div>
                        <div className='info-row-position'>
                          {v?.send_user?.position}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='chat-wrap'>
                    {!v.parent_message ? (
                      <div className='chat-item'>
                        {v?.content ? (
                          v?.content
                        ) : (
                          <ImageMessage
                            fileId={v?.files?.id}
                            REACT_APP_API={process.env.REACT_APP_API}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='chat-item'>
                        <div className='reple-title'>
                          {isLang === 'en' && 'Reply to '}
                          {v?.parent_message?.send_user?.name}{' '}
                          {v?.parent_message?.send_user?.position}
                          {isLang === 'ko' && '에게 답장'}
                        </div>
                        <div className='reple-content'>
                          {v?.parent_message?.content}
                        </div>
                        {v?.content}
                      </div>
                    )}
                    <div className='left-date-wrap'>
                      <div className='left-repeat-item'>
                        {dayjs(v?.created_at).format('YY.MM.DD')}
                      </div>
                      <div className='left-repeat-item'>
                        {dayjs(v?.created_at).format('HH:mm')}
                      </div>
                    </div>
                    <div className='reple-icon-wrap'>
                      <img
                        onClick={() => {
                          reple ? setReple('') : setReple(v);
                        }}
                        src={repleSVG}
                        alt='답장 아이콘'
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='line'></div>
          <div className='bottom-input-wrap'>
            {reple ? (
              <div className='reple-wrap'>
                <div className='reple-title-wrap'>
                  <div className='reple-title'>
                    {isLang === 'en' && 'Reply to '}
                    {reple?.send_user.name} {reple?.send_user.position}
                    {isLang === 'ko' && '에게 답장'}
                  </div>
                  <div className='reple-content'>{reple?.content}</div>
                </div>
                <div className='reple-close'>
                  <img
                    src={IcDelSVG}
                    alt='삭제 아이콘'
                    onClick={() => setReple('')}
                  />
                </div>
              </div>
            ) : null}
            <div className='chat-input-wrap'>
              <div className='fileupload-wrap'>
                <ChatFileUpload
                  setImg={(e: any) => {
                    handleSendImage(e);
                  }}
                  icon={'plus_01_ic'}
                  buttonStyle={UploadButton}
                  limit={1}
                  disabledPreview={true}
                />
              </div>
              <div className='input-container'>
                <input
                  type='text'
                  value={message}
                  onChange={(e: any) => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={handleEnterEvent}
                  placeholder={reple ? '답장 쓰기' : ''}
                  className='chat-input'
                />
                <img
                  src={message?.length <= 0 ? IcSendDisabledSVG : IcSendSVG}
                  alt=''
                  onClick={handleSendMessage}
                  className='sand-icon'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='root-inner'>
          <div className='top-nav-wrap'>
            <img
              src={BackIcSVG}
              alt='뒤로가기 아이콘'
              className='setting-back-icon'
              onClick={() => {
                setIsMenu(false);
              }}
            />
            <div className='chatroom-title'>{chatting.chatroolSetting}</div>
          </div>
          <div className='line'></div>
          <div className='chat-setting'>
            <div className='chat-setting-switch'>
              <div>{chatting.notiSetting}</div>
              <Switch onClick={handleSetChatRoomConfig} checked={checked} />
            </div>
            <div>
              <span className='exit-btn' onClick={() => setIsModalOpen(true)}>
                {chatting.chatroolLeave}
              </span>
            </div>
          </div>
        </div>
      )}
      <TextModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => handleExitChatRoom()}
        content={chatting.wouldChatroolLeave}
        submitText={chatting.exit}
      />
    </div>
  );
};

const rootCss = () => {
  return css`
    width: 100%;
    background-color: white;
    border-radius: 20px;
    -webkit-user-drag: none;
    cursor: default;

    .top-nav-wrap {
      display: flex;
      align-items: center;
      padding: 0px 20px;
      width: 100%;
      height: 60px;
    }

    .back-icon {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
    }

    .setting-back-icon {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      margin-right: -20px;
    }

    .menu-icon {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      width: 15px;
    }

    .chatroom-title {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      color: #222;
      margin-right: auto;
      margin-left: auto;
    }

    .chat-content {
      padding: 20px 3px 0 20px;
      width: 100%;
      height: 372px;
      display: flex;
      position: relative;
      flex-direction: column-reverse;
      overflow: hidden;
      overflow-y: scroll;
    }

    .bottom-input-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px;
      flex-direction: column;
    }

    .reple-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 6px 12px 10px 0;
      justify-content: space-between;
    }

    .reple-title-wrap {
      display: flex;
      flex-direction: column;
      padding-left: 6px;
      align-items: flex-start;
      justify-content: center;
    }

    .reple-title {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.24px;
      color: #444;
      margin-bottom: 4px;
    }

    .reple-content {
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #444;
      display: block;
      white-space: nowrap;
      max-width: 280px;
      width: auto;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
    }

    .reple-close {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .reple-line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #e3e3e3;
      margin-right: 68px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .chat-input-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    .fileupload-wrap {
      display: flex;
      justify-content: center;
    }

    .input-container {
      display: flex;
      align-items: center;
      width: 280px;
      height: 40px;
      border-radius: 50px;
      border: solid 1px #cfcfcf;
      background-color: #fff;
    }

    .chat-input {
      padding: 11px 20px;
      font-size: 16px;
      letter-spacing: -0.32px;
      text-align: left;
      color: #000;
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #000;
    }

    .sand-icon {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      cursor: pointer;
      user-select: none;
    }

    .me-chat-wrap {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .chat-wrap {
      display: flex;
      flex-direction: row;
    }

    .right-date-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 8px 5px 0;
      text-align: right;
    }

    .right-date-item {
      font-size: 11px;
      font-weight: normal;
      color: #777;
      align-self: flex-end;
    }

    .me-chat-item {
      display: block;
      padding: 12px 14px 13px;
      background-color: #298dff;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      white-space: pre-line;
      max-width: 200px;
      width: auto;
      overflow: hidden;
      word-break: break-all;
      margin-top: 8px;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #fff;
    }

    .chat-item {
      display: block;
      padding: 12px 14px 13px;
      background-color: #f2f2f2;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      white-space: pre-line;
      max-width: 200px;
      width: auto;
      overflow: hidden;
      word-break: break-all;
      margin: 8px 0 10px;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #000;
    }

    .character-wrap {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .user-info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .info-row-wrap {
      display: flex;
      flex-direction: row;
    }

    .info-row-name {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.24px;
      color: #000;
      margin-right: 6px;
    }

    .info-row-position {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.24px;
      color: #000;
    }

    .left-date-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 8px 0 10px 5px;
      text-align: left;
    }

    .left-repeat-item {
      font-size: 11px;
      font-weight: normal;
      color: #777;
      align-self: flex-start;
    }

    .reple-icon-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 8px 0 10px 5px;

      img {
        cursor: pointer;
      }
    }

    .chat-setting {
      padding: 20px;
      font-size: 14px;

      & > div {
        margin-bottom: 20px;
      }

      .exit-btn {
        cursor: pointer;
        color: red;
      }
    }

    .chat-setting-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #e3e3e3;
      margin-right: 68px;
    }
  `;
};

const UploadButton = {
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  border: 'solid 1px #999999',
  backgroundColor: '#fff',
};

const ImageMessage = styled.div`
  width: 170px;
  height: 170px;
  background-position: center;
  background-size: 170px auto;
  background-repeat: no-repeat;
  background-image: ${(props: ProfileImageProps) =>
    `url(${props.REACT_APP_API}/files/${props.fileId})`};
`;

const Switch = styled.div<{ checked: boolean }>`
  position: relative;
  width: 41px;
  height: 26px;
  background: #e3e3e3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  margin-left: 10px;
  cursor: pointer;

  ${({ checked }) =>
    checked
      ? css`
          background: #258fff;
          &:before {
            transition: 300ms all;
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 35px;
            top: 50%;
            left: 2px;
            background: white;
            transform: translate(80%, -50%);
          }
        `
      : css`
          &:before {
            transition: 300ms all;
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 35px;
            top: 50%;
            left: 4px;
            background: white;
            transform: translate(0, -50%);
          }
        `}
`;

export default PrivateChat;
