import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { setIsAlert } from 'store/alert';
import {
  setAuthValue,
  setCreatePermission,
  setIsMypage,
  setPermission,
  setPrincipal,
  setUserIdValue,
} from 'store/auth';
import { setIsMobile, setUiValue } from 'store/ui';
import { setIsChat, setSelectedUserInfo } from 'store/chat';
import { fetchPermission, fetchUserInfo } from 'api/auth';

let currentPath = '';

export const AppViewModel = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { userId } = useSelector((s: any) => s.auth);
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  // 화면사이즈 변동 감지
  useEffect(() => {
    if (width < 800) {
      dispatch(setIsMobile(true));
    } else {
      dispatch(setIsMobile(false));
    }
  }, [width, dispatch]);

  // 라우터 이동 시 페이지 새로고침
  useEffect(() => {
    if (currentPath === location.pathname) window.location.reload();
    currentPath = location.pathname;
    let path = currentPath;
    if (currentPath === '/') path = '/Home';
    const setPath = path
      .split('/')[1]
      .replace(/\b[a-z]/, (letter) => letter.toUpperCase());

    dispatch(setIsChat(false));
    dispatch(setIsAlert(false));
    dispatch(setIsMypage(false));
    dispatch(setUiValue(setPath));
    dispatch(setSelectedUserInfo(undefined));
  }, [location, dispatch]);

  useEffect(() => {
    if (accessToken) {
      handleUserInfo();
      if (userId !== 0) {
        handlePermission();
      }
    }
  }, [accessToken, userId]);

  // 유저 정보 핸들러
  const handleUserInfo = async () => {
    const userInfoData = await fetchUserInfo();
    if (userInfoData) {
      dispatch(setPrincipal(userInfoData.result));
      dispatch(setAuthValue(userInfoData.result.name));
      dispatch(setUserIdValue(userInfoData.result.id));
    }
  };

  // 유저 권한 핸들러
  const handlePermission = async () => {
    const permissionData = await fetchPermission(userId);
    if (permissionData) {
      dispatch(setPermission(permissionData.result.ADMIN_ACCEPT));
      dispatch(setCreatePermission(permissionData.result.POST_CREATE_ACCEPT));
    }
  };

  return {};
};
