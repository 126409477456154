import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchFileUpload,
  fetchGetLoginedUserInfo,
  fetchUpdateUserInfo,
  logout,
} from 'api/auth';
import SetErrorBar from 'utils/SetErrorBar';
import { POSITIONS_OPTIONS, PREFIX_NUMBER_OPTIONS } from 'utils/constants';
import { useMypageEssentialDataCheck } from 'hooks/useEssentialCheck';

export const MypageViewModel = (
  values: any,
  setValue: (e: any, v: any) => void
) => {
  const navigate = useNavigate();
  const { userId } = useSelector((s: any) => s.auth);
  const [img, setImg] = useState<any>([]);
  const [loginInfo, setLoginInfo] = useState<any>();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);

  // 필수항목 체크 훅
  const signUpEssential = useMypageEssentialDataCheck(values);

  useEffect(() => {
    if (userId !== 0) handleUserInfo();
  }, [userId]);

  useEffect(() => {
    setValue('email', loginInfo?.user_name);
    setValue('name', loginInfo?.name);
    const phone1 = loginInfo?.phone.substr(0, 3);
    const phone2 = loginInfo?.phone.substr(3, 8);
    for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
      if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
        setValue('userPhone1', PREFIX_NUMBER_OPTIONS[i]);
      }
    }
    setValue('userPhone2', phone2);
    for (let i = 0; i < POSITIONS_OPTIONS.length; i++) {
      if (POSITIONS_OPTIONS[i].value === loginInfo?.position) {
        setValue('position', POSITIONS_OPTIONS[i]);
      }
    }
  }, [
    loginInfo?.user_name,
    loginInfo?.name,
    loginInfo?.phone,
    loginInfo?.position,
  ]);

  useEffect(() => {
    if (signUpEssential) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [signUpEssential]);

  useEffect(() => {
    setImg(values.profile);
  }, [values.profile]);

  const handleUserInfo = async () => {
    const userInfoData = await fetchGetLoginedUserInfo(userId);

    if (userInfoData) {
      setLoginInfo(userInfoData.result);
    }
  };

  const handleUserInfoModify = async () => {
    let imgAttachment: any = [];

    if (img.length > 0) {
      const imgfile = img[0];
      const fileUploadData = await fetchFileUpload(imgfile);
      imgAttachment.push(fileUploadData.data.result.id);
    }

    let payload: any = {
      user_name: values.email,
      name: values.name,
      phone: values.userPhone1.value + values.userPhone2,
      position: values.position.value,
    };
    if (values.password) {
      payload = {
        ...payload,
        password: values.password,
      };
    }
    if (imgAttachment) {
      payload = {
        ...payload,
        profile_image_id: imgAttachment[0],
      };
    }

    const updateUserInfo = await fetchUpdateUserInfo(payload);

    if (updateUserInfo.status === 200) {
      if (values.password) {
        SetErrorBar('회원정보가 수정되었습니다. 재로그인해주세요.');
        logout();
        navigate('/login', { replace: true });
      } else {
        SetErrorBar('회원정보가 수정되었습니다.');
        navigate('/', { replace: true });
      }
    }
  };

  return { loginInfo, isAbledBtn, handleUserInfoModify };
};
