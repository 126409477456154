export const getSocketHost = () => {
  return 'http://39.115.239.181:10001';
};

export const ERROR_COLOR = '#ff4b4b';

export const PREFIX_NUMBER_OPTIONS = [
  { value: '010', label: '010' },
  { value: '011', label: '011' },
  { value: '016', label: '016' },
  { value: '017', label: '017' },
  { value: '019', label: '019' },
];

export const POSITIONS_OPTIONS = [
  { value: '프론트엔드', label: '프론트엔드' },
  { value: '백엔드', label: '백엔드' },
  { value: '풀스텍', label: '풀스텍' },
];
