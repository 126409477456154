import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    isAlert: false,
  },
  reducers: {
    setIsAlert: (state, action) => {
      state.isAlert = action.payload;
    },
  },
});

export const { setIsAlert } = alertSlice.actions;

export default alertSlice.reducer;
