/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useLang } from "utils/lang/useLang";

const MypageMenu = () => {
  const { auth } = useLang();
  const navigate = useNavigate();
  const root = rootCss();

  return (
    <div css={root}>
      <div className="root-inner">
        <div
          className="mypage-menu"
          onClick={() => {
            navigate("/mypage");
          }}
        >
          {auth.editUserInfo}
        </div>
        <div className="line"></div>
        <div
          className="mypage-menu"
          onClick={() => {
            navigate("/adminpage", {
              state: {
                isUpdate: false,
              },
            });
          }}
        >
          {auth.adminPage}
        </div>
      </div>
    </div>
  );
};

const rootCss = () => {
  return css`
    position: absolute;
    z-index: 9999999999;
    top: 80px;
    right: 60px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    box-shadow: 6px 6px 10px #5b5b5b;

    .root-inner {
      width: 200px;
      .line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #e3e3e3;
        margin-right: 68px;
      }

      .mypage-menu {
        padding: 15px 20px;
        cursor: pointer;
      }

      .mypage-menu:hover {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
    }
  `;
};

export default MypageMenu;
