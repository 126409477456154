import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDeletePortfolio, fetchPortfolioDetail } from 'api/portfolio';

export const WorkDetailViewModel = (portfolioId: string, type: string) => {
  const navigate = useNavigate();
  const [resultData, setResultData] = useState<any>();
  const [subContentsData, setSubContentsData] = useState<any>([]);
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    handleGetDetailPortfolio();
  }, []);

  // 포트폴리오 상세 핸들러
  const handleGetDetailPortfolio = async () => {
    const portfolioData: any = await fetchPortfolioDetail(portfolioId);

    if (portfolioData.result) {
      setResultData(portfolioData.result);
      const contentArr = [];
      for (const element of [0, 1, 2, 3, 4]) {
        contentArr[element] = {
          sub_title: portfolioData?.result?.raw[`sub_title_${element + 1}`],
          sub_content: portfolioData?.result?.raw[`sub_content_${element + 1}`],
          sub_picture:
            portfolioData?.result?.raw[`sub_picture_${element + 1}_info`],
        };
      }

      const arr = contentArr
        .map((sub: any) => {
          if (
            sub.sub_title !== null &&
            sub.sub_content !== null &&
            sub.sub_picture_info !== null
          ) {
            return sub;
          }
        })
        .filter((e: any) => e !== undefined);
      setSubContentsData(arr);
    }
  };

  // 포트폴리오 삭제 핸들러
  const handleDeletePortfolio = async () => {
    const deleteData = await fetchDeletePortfolio(portfolioId);

    if (deleteData) {
      navigate(`/works?type=${type}`, { state: { type: type } });
    }
  };

  return { accessToken, resultData, subContentsData, handleDeletePortfolio };
};
