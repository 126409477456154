import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import alertReducer from './alert';
import authReducer from './auth';
import uiReducer from './ui';
import chatReducer from './chat';
import langReducer from './lang';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  ui: uiReducer,
  chat: chatReducer,
  lang: langReducer,
});

const persistConfig = {
  key: 'root',
  storage, // 로컬 스토리지에 저장
  whitelist: ['lang'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
