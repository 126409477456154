import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import changeAuthSchma from 'utils/validation/changeAuthSchma';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { StringProps } from 'utils/Types';
import { ChangeAuthViewModel } from 'services/ChangeAuthViewModel';
import { useLang } from 'utils/lang/useLang';

const defaultValues: StringProps = {
  changepw: '',
};

const ChangeAuth = () => {
  const { auth } = useLang();
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<StringProps>({
    defaultValues,
    resolver: yupResolver(changeAuthSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();
  const model = ChangeAuthViewModel(values);

  return (
    <Layout>
      <SearchAuthStyled>
        <div className='searchpw-inner'>
          <h2>{auth.changePassword}</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  model?.handleChangeAuth();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='changepw'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.newPassword}
                    errors={errors}
                  />
                )}
              />
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.changePassword}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.changePassword}
              </Button>
            )}
          </form>
        </div>
      </SearchAuthStyled>
    </Layout>
  );
};

const SearchAuthStyled = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px 0 0 20px;

  & .searchpw-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & form {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default ChangeAuth;
