import { fetchDeleteResume, fetchGetResume } from 'api/resume';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SetErrorBar from 'utils/SetErrorBar';

export const ResumeViewModel = () => {
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState<number | null>(null); // 총 페이지수
  const [pageNumber, setPageNumber] = useState<number>(1); // 현재 페이지
  const [pageScale, setPageScale] = useState<number>(1); // 페이지 스케일
  const [pdfFilePath, setPdfFilePath] = useState<any>();
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    handleGetResume();
  }, []);

  // 이력서 데이터 핸들러
  const handleGetResume = async () => {
    const resumeData = await fetchGetResume();

    if (resumeData) {
      setPdfFilePath(resumeData.result);
    }
  };

  // 이력서 삭제 핸들러
  const handleDeleteResume = async () => {
    const deleteData = await fetchDeleteResume(pdfFilePath?.raw[0].id);

    if (deleteData.status === 200) {
      SetErrorBar('이력서가 삭제 되었습니다');
      navigate('/resume', { replace: true });
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return {
    accessToken,
    pdfFilePath,
    numPages,
    pageScale,
    pageNumber,
    setPageScale,
    setPageNumber,
    handleDeleteResume,
    onDocumentLoadSuccess,
  };
};
