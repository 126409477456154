import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import { LoginProps } from 'utils/Types';
import loginSchma from 'utils/validation/loginSchma';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { LoginViewModel } from 'services/LoginViewModel';
import { useLang } from 'utils/lang/useLang';

const defaultValues: LoginProps = {
  userId: '',
  userPw: '',
  autoLogin: false,
  rememberId: false,
};

const Login = () => {
  const { auth } = useLang();
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<LoginProps>({
    defaultValues,
    resolver: yupResolver(loginSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();
  const model = LoginViewModel(values, setValue);

  return (
    <Layout>
      <LoginStyled>
        <div className='login-inner'>
          <h2>{auth.login}</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  model?.handleLogin();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='userId'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.email}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='userPw'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.password}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='side-sec'>
              <div className='checkbox-box'>
                <Checkbox
                  checked={model?.autoLogin}
                  onChange={(e) => model?.setAutoLogin(e.target.checked)}
                >
                  {auth.autoLogin}
                </Checkbox>
                <Checkbox
                  checked={model?.isSaveId}
                  onChange={(e) => model?.setIsSaveId(e.target.checked)}
                >
                  {auth.rememberId}
                </Checkbox>
              </div>
              <div className='search-password'>
                <button
                  type='button'
                  onClick={() => navigate('/login/searchpw')}
                >
                  {auth.findPassword}
                </button>
              </div>
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.login}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.login}
              </Button>
            )}
          </form>
        </div>
      </LoginStyled>
    </Layout>
  );
};

const LoginStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 20px 20px 0 20px;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  & .login-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .checkbox-box {
    display: flex;
    & label {
      margin-right: 30px;
    }
  }

  & .side-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  & .search-password {
    button {
      font-size: 16px;
      color: gray;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

export default Login;
