import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsChat } from 'store/chat';
import { setChatTitle } from 'store/chat';
import { setSelectedUserInfo } from 'store/chat';
import Button from 'components/Button/Button';
import ImgModal from 'components/ImgModal/ImgModal';
import TextModal from 'components/TextModal/TextModal';
import ProfilesImage from 'components/Profile/ProfilesImage';
import ClosePNG from 'assets/images/close.png';
import IcPhoneBkSVG from 'assets/images/ic-phone-bk.svg';
import IcEmailBkSVG from 'assets/images/ic-email-bk.svg';
import IcCellphoneBkSVG from 'assets/images/ic-cellphone-bk.svg';
import { IMemberTable } from 'utils/Types';
import { FriendListViewModel } from 'services/FriendListViewModel';
import { useLang } from 'utils/lang/useLang';

const MobileFriendListTable = ({
  data,
  mutate,
  requestsRefetch,
  setSelectedFriendInfo,
  setTabType,
  me,
}: IMemberTable) => {
  const { friendsManage } = useLang();
  const dispatch = useDispatch();
  const model = FriendListViewModel(
    data,
    mutate,
    requestsRefetch,
    setTabType,
    setSelectedFriendInfo
  );

  return (
    <MemberListContainer>
      {data && (
        <MemberCell>
          <div>
            <ExitIcon
              src={ClosePNG}
              onClick={() => {
                console.log('ddddd');

                setSelectedFriendInfo(null);
              }}
            />
            <CellLeftSection>
              <div className='mo_profile_align'>
                <ProfilesImage
                  width={80}
                  height={80}
                  fileId={data?.profile_image_id}
                  containerStyle={{
                    border: 'solid 1px #e3e3e3',
                    borderRadius: '100px',
                  }}
                />
                <MemberInfoWrap>
                  <NameInfo>{data?.name}</NameInfo>
                  <PositionInfo>{data?.position}</PositionInfo>
                </MemberInfoWrap>
              </div>
              <MemberRow>
                <MemberContactWrap>
                  <ContactInfo>
                    <Icon src={IcCellphoneBkSVG} />
                    {model?.addHyphen(data?.phone)}
                  </ContactInfo>
                  <ContactInfo>
                    <Icon src={IcPhoneBkSVG} />
                    {model?.addHyphen(data?.tel)}
                  </ContactInfo>
                  <ContactInfo>
                    <Icon src={IcEmailBkSVG} />
                    {data?.user_name}
                  </ContactInfo>
                </MemberContactWrap>
              </MemberRow>
              <MemberRow>
                {me?.id !== data.id && !model?.getRequest ? (
                  <MemberButtonWrap>
                    <Button
                      variant=''
                      preset={0}
                      type='button'
                      w={'200'}
                      h={'48'}
                      fSize={'14'}
                      color={model?.isFriend ? 'red' : ''}
                      onClick={() =>
                        model?.isFriend
                          ? model?.setIsDeleteModalOpen(true)
                          : model?.setIsApprovalModalOpen(true)
                      }
                    >
                      {model?.isFriend
                        ? friendsManage.deleteFriend
                        : friendsManage.addFriend}
                    </Button>
                    <span></span>
                    <Button
                      variant=''
                      preset={0}
                      type='button'
                      w={'200'}
                      h={'48'}
                      fSize={'14'}
                      onClick={() => {
                        setSelectedFriendInfo(null);
                        dispatch(setSelectedUserInfo(data));
                        dispatch(setIsChat(true));
                        dispatch(setChatTitle('c'));
                      }}
                    >
                      {friendsManage.goChatting}
                    </Button>
                  </MemberButtonWrap>
                ) : null}
              </MemberRow>
            </CellLeftSection>
            {model?.getRequest ? (
              <AuthorityButtonWrap>
                <Button
                  variant=''
                  preset={0}
                  type='button'
                  w={'200'}
                  h={'48'}
                  fSize={'14'}
                  onClick={() => model?.setIsApprovalModalOpen(true)}
                >
                  {friendsManage.approval}
                </Button>
                <span></span>
                <Button
                  variant=''
                  preset={0}
                  type='button'
                  w={'200'}
                  h={'48'}
                  fSize={'14'}
                  disabled
                  onClick={() => model?.setIsRefuseModalOpen(true)}
                >
                  {friendsManage.reject}
                </Button>
              </AuthorityButtonWrap>
            ) : null}
          </div>
        </MemberCell>
      )}
      <TextModal
        open={model?.isApprovalModalOpen}
        onClose={() => model?.setIsApprovalModalOpen(false)}
        onSubmit={() => model?.handleApproveFriend()}
        content={
          model?.getRequest
            ? friendsManage.wouldFriendRequest
            : friendsManage.wouldAddFriend
        }
        submitText={
          model?.getRequest ? friendsManage.approval : friendsManage.add
        }
      />
      <TextModal
        open={model?.isRefuseModalOpen}
        onClose={() => model?.setIsRefuseModalOpen(false)}
        onSubmit={() => model?.handleRefuseFriend()}
        content={friendsManage.wouldDeclineFriend}
        submitText={friendsManage.reject}
      />
      <TextModal
        open={model?.isDeleteModalOpen}
        onClose={() => model?.setIsDeleteModalOpen(false)}
        onSubmit={() => model?.handleDeleteFriend()}
        content={friendsManage.wouldDeleteFriend}
        submitText={friendsManage.delete}
      />
      <ImgModal
        open={model?.confirmApproveModal}
        onClose={() => model?.setConfirmApproveModal(false)}
        content={friendsManage.friendAdded}
        submitText={friendsManage.confirm}
      />
      <ImgModal
        open={model?.confirmDeleteMember}
        onClose={() => model?.setConfirmDeleteMember(false)}
        content={friendsManage.deleted}
        submitText={friendsManage.confirm}
      />
      <ImgModal
        open={model?.confirmRefuseMember}
        onClose={() => model?.setConfirmRefuseMember(false)}
        content={friendsManage.refused}
        submitText={friendsManage.confirm}
      />
    </MemberListContainer>
  );
};

const ScrollBox = styled.div`
  /* 스크롤바 설정*/
  &::-webkit-scrollbar {
    width: 17px;
  }

  /* 스크롤바 막대 설정*/
  &::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: #c7c7c7;
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;

    background-clip: padding-box;
    border: 6px solid transparent;
  }

  /* 스크롤바 뒷 배경 설정*/
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const MemberListContainer = styled(ScrollBox)``;

const MemberCell = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #f2f2f2;

  & > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 35px 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const MemberRow = styled.div`
  margin-top: 30px;
`;

const MemberInfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;

const MemberButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  & > span {
    width: 10px;
  }
`;

const CellLeftSection = styled.div`
  .mo_profile_align {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
`;

const CompanyInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #777;
  margin-right: 6px;
`;

const NameInfo = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
  color: #000;
  margin-right: 2px;
`;

const PositionInfo = styled.div`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.32px;
  color: #222;
  margin-top: 3px;
  margin-left: 5px;
`;

const MemberContactWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  color: #000;

  margin-bottom: 8px;
`;

const ExitIcon = styled.img`
  float: right;
  width: 24px;
  height: 24px;
`;

const Icon = styled.img`
  width: 20px;
  height: 29px;

  margin-right: 14px;
`;

const AuthorityButtonWrap = styled.div`
  display: flex;
  justify-content: center;

  & > span {
    width: 10px;
  }
`;

export default MobileFriendListTable;
