import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import ChatApi from 'api/chat';
import PrivateChatApi from 'api/privateChat';
import { setChatTitle } from 'store/chat';
import { ChatTitleEnum } from 'utils/Enum';
import PrivateChat from 'components/Chat/PrivateChat';
import ProfilesImage from 'components/Profile/ProfilesImage';
import MobileSearchInput from 'components/Input/MobileSearchInput';
import IcChatSVG from 'assets/images/ic-chat.svg';
import { useLang } from 'utils/lang/useLang';
import { fetchFriend } from 'api/friends';

const Chat = () => {
  const { chatting } = useLang();
  const dispatch = useDispatch();
  const { principal } = useSelector((s: any) => s.auth);
  const selectedChatUser = useSelector((s: any) => s.chat);
  const { isChatTitle } = useSelector((s: any) => s.chat);
  const [search, setSearch] = useState<string>('');
  const [chatList, setChatList] = useState<any>();
  const [messages, setMessages] = useState<any>();
  const [privateChat, setPrivateChat] = useState<any>();
  const [privateChats, setPrivateChats] = useState<any>();
  const [friendsList, setFriendsList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedUserInfo, setSelectedUserInfo] = useState<any>(null);
  const [openPrivateChat, setOpenPrivateChat] = useState(false);
  const [oepnChat, setOpenChat] = useState(false);
  const root = rootCss(isChatTitle);

  // 상대와의 채팅 정보 조회
  useEffect(() => {
    if (selectedUserId) {
      handleprivateChatInfo(String(selectedUserId));
    }
  }, [selectedUserId]);

  // 채팅 리스트 조회
  useEffect(() => {
    handlePrivateChatList();
  }, []);

  // 채팅방 메세지 리스트
  useEffect(() => {
    if (privateChat) {
      handleChatList();
    }
  }, [privateChat]);

  // 친구 리스트 조회
  useEffect(() => {
    if (isChatTitle === ChatTitleEnum.F || search) friendList();
  }, [isChatTitle, search]);

  // 채팅/친구 버튼 클릭 시 찾기 상태 초기화
  useEffect(() => {
    setSearch('');
  }, [isChatTitle]);

  // 선택한 친구 정보 가져오기
  useEffect(() => {
    if (selectedChatUser.selectedUserInfo) {
      setOpenPrivateChat(true);
      setSelectedUserInfo(selectedChatUser.selectedUserInfo);
      setSelectedUserId(selectedChatUser?.selectedUserInfo?.id);
    }
  }, [selectedChatUser]);

  const handleprivateChatInfo = async (selectedUserId: string) => {
    await PrivateChatApi.privateChatInfo(selectedUserId)
      .then((res) => {
        setPrivateChat(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePrivateChatList = async () => {
    await PrivateChatApi.privateChatList()
      .then((res) => {
        setPrivateChats(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChatList = async () => {
    await ChatApi.chatList(privateChat.chat_room_id)
      .then((res) => {
        setMessages(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const friendList = async () => {
    const friendData = await fetchFriend(search !== '' ? search : '');

    if (friendData) {
      setFriendsList(friendData.result);
    }
  };

  const searchedPrivateChats = useMemo(() => {
    if (!privateChats) return [];
    const sortedData = privateChats.sort((a: any, b: any) => {
      const createdAta = a.latest_chat_message.created_at;
      const createdAtb = b.latest_chat_message.created_at;
      return new Date(createdAtb).getTime() - new Date(createdAta).getTime();
    });

    return sortedData.filter((v: any) => {
      return v?.user.name?.includes(search);
    });
  }, [privateChats, search]);

  const handleChangeSearch = (e: any) => {
    const searched = e;
    if (isChatTitle === ChatTitleEnum.C) {
      const searchedItem = privateChats.filter((el: any) => {
        return el.name === searched;
      });
      setChatList(searchedItem);
    } else {
      const searchedItem = friendsList.filter((el: any) => {
        return el.name === searched;
      });
      setFriendsList(searchedItem);
    }
  };

  return (
    <div css={root}>
      {!openPrivateChat ? (
        <div className='rootInner'>
          <div className='chat-header'>
            <ul className='chat-title'>
              <li
                className='chat'
                onClick={() => {
                  dispatch(setChatTitle(ChatTitleEnum.C));
                }}
              >
                {chatting.chat}
              </li>
              <li
                className='friend'
                onClick={() => {
                  dispatch(setChatTitle(ChatTitleEnum.F));
                }}
              >
                {chatting.friend}
              </li>
            </ul>
          </div>
          <MobileSearchInput
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
              handleChangeSearch(e.target.value);
            }}
            containerStyle={{ width: '320px' }}
            placeholder={chatting.nameSearch}
          />
          {isChatTitle === ChatTitleEnum.C && (
            <div>
              <div>
                {searchedPrivateChats.map((v: any, i: number) => {
                  return (
                    <div key={i}>
                      <div
                        onClick={() => {
                          setOpenPrivateChat(true);
                          setSelectedUserId(v?.user.id);
                          setSelectedUserInfo(v?.user);
                        }}
                        className='chat-menu-list'
                      >
                        <ProfilesImage
                          width={44}
                          height={44}
                          fileId={v?.user?.profile_image_id}
                          containerStyle={{
                            border: 'solid 1px #e3e3e3',
                            borderRadius: '100px',
                            marginLeft: '20px',
                          }}
                        />
                        <div className='chat-left-wrap'>
                          <div className='chat-left-inner'>
                            <div className='friend-name'>{v?.user.name}</div>
                            <div className='friend-position'>
                              {v?.user.position}
                            </div>
                          </div>
                          <div className='chat-left-content'>
                            {v?.latest_chat_message.content
                              ? v?.latest_chat_message.content
                              : chatting.sendImage}
                          </div>
                        </div>
                        <div className='chat-right-wrap'>
                          <div className='date-wrap'>
                            <div
                              className='date-inner'
                              style={{ marginRight: 4 }}
                            >
                              {dayjs(v?.latest_chat_message.created_at).format(
                                'MM.DD'
                              )}
                            </div>
                            <div className='date-inner'>
                              {dayjs(v?.latest_chat_message.created_at).format(
                                'HH:mm'
                              )}
                            </div>
                          </div>
                          {v.unreaded_count !== 0 && (
                            <div className='notification-count'>
                              {v.unreaded_count}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='line'></div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {isChatTitle === ChatTitleEnum.F && (
            <div>
              <div>
                {friendsList.map((v: any, i: number) => {
                  return (
                    <div className='friend-list' key={i}>
                      <ProfilesImage
                        width={44}
                        height={44}
                        fileId={v?.profile_image_id}
                        containerStyle={{
                          border: 'solid 1px #e3e3e3',
                          borderRadius: '100px',
                          marginLeft: '20px',
                        }}
                      />
                      <div className='friend-wrap'>
                        <div className='friend-name'>{v.name}</div>
                        <div className='friend-position'>{v.position}</div>
                      </div>
                      <div
                        className='friend-icon'
                        onClick={() => {
                          setSelectedUserId(v?.id);
                          setSelectedUserInfo(v);
                          setOpenPrivateChat(true);
                        }}
                      >
                        <img
                          src={IcChatSVG}
                          alt='채팅 아이콘'
                          className='chat-img'
                        />
                        <div className='chat-text'>{chatting.chatStart}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='rootInner'>
          <PrivateChat
            messages={messages}
            selectedUserInfo={selectedUserInfo}
            setOpenPrivateChat={setOpenPrivateChat}
            setOpenChat={setOpenChat}
            userInfo={principal}
            mutateMessages={handleChatList}
            chatRoomId={privateChat?.chat_room_id}
            handleprivateChatInfo={handleprivateChatInfo}
            handlePrivateChatList={handlePrivateChatList}
            selectedUserId={selectedUserId}
          />
        </div>
      )}
    </div>
  );
};

const rootCss = (isChatTitle: string) => {
  return css`
    position: absolute;
    z-index: 9999;
    top: 80px;
    right: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px #d9d9d9;

    .rootInner {
      width: 355px;
      min-height: 500px;
      overflow-y: scroll;
    }

    .rootInner::-webkit-scrollbar {
      display: none;
    }

    .chat-title {
      display: flex;
      margin-bottom: 10px;
      li {
        margin: 20px 0 0 20px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }

      .chat {
        color: ${isChatTitle === ChatTitleEnum.C ? '#000' : '#d1d1d1'};
      }

      .friend {
        color: ${isChatTitle === ChatTitleEnum.F ? '#000' : '#d1d1d1'};
      }
    }

    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .setting-img {
      display: flex;
      margin-top: 10px;
      margin-right: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .chat-menu-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      cursor: pointer;
      padding: 16px 0;
    }

    .chat-left-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-align: center;
      padding: 3px 0;
      margin-left: 10px;
    }

    .chat-left-inner {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
    }

    .chat-left-content {
      width: 160px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      margin-left: 11px;
    }

    .chat-right-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding: 3px 0;
      margin-left: auto;
      margin-right: 30px;
    }

    .date-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .date-inner {
      font-size: 12px;
      font-weight: 300;
      color: #777777;
    }

    .notification-count {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px 2px 8px;
      border-radius: 50%;
      background-color: #ff5517;
      font-size: 10px;
      font-weight: normal;
      color: #fff;
    }

    .friend-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: 1px solid #f2f2f2;
      padding: 16px 0;
    }

    .friend-wrap {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .friend-name {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      margin: 0 2px 0 10px;
    }

    .friend-position {
      font-size: 12px;
      font-weight: normal;
      color: #222;
      margin-left: 5px;
    }

    .friend-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
      margin-right: 30px;
      cursor: pointer;
    }

    .chat-img {
      width: 20px;
      height: 20px;
    }

    .chat-text {
      height: 17px;
      color: #444444;
      font-size: 12px;
      font-weight: normal;
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #e3e3e3;
      margin-right: 68px;
    }
  `;
};

export default Chat;
