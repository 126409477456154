import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SetErrorBar from "utils/SetErrorBar";
import { ElementProps } from "utils/Types";

const PermissionGuard = ({ children }: ElementProps) => {
  const navigate = useNavigate();
  const { permission } = useSelector((s: any) => s.auth);

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");

  useEffect(() => {
    if (!permission || !accessToken) {
      SetErrorBar("관리자 권한이 없으면 접근이 제한됩니다.");
      navigate("/", { replace: true });
    }
  }, []);

  if (!permission || !accessToken) {
    return null;
  }
  return children;
};

export default PermissionGuard;
