import request from './request';

// 친구 리스트
export const fetchFriendsList = async () => {
  try {
    const result = await request('/friends/user_list', 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 받은 요청 리스트
export const fetchFriendsRequests = async () => {
  try {
    const result = await request('/friends/requests', 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 친구 데이터
export const fetchFriend = async (keyword: string) => {
  try {
    const result = await request('/friends', 'get', {}, { keyword });

    return result;
  } catch (error: any) {
    return error;
  }
};

// 친구 요청
export const fetchPostFriend = async (userId: number) => {
  try {
    const result = await request(`/friends/${userId}`, 'post', {}, { userId });

    return result;
  } catch (error: any) {
    return error;
  }
};

// 친구 삭제
export const fetchDeleteFriend = async (userId: number) => {
  try {
    const result = await request(
      `/friends/${userId}`,
      'delete',
      {},
      { userId }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};
