import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import mypageSchema from 'utils/validation/mypageSchema';
import { PREFIX_NUMBER_OPTIONS, POSITIONS_OPTIONS } from 'utils/constants';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import { MypageProps } from 'utils/Types';
import FileInput from 'components/FileInput/FileInput';
import { MypageViewModel } from 'services/MypageViewModel';
import { useLang } from 'utils/lang/useLang';

const defaultValues: MypageProps = {
  profile: [],
  email: '',
  password: '',
  passwordCheck: '',
  name: '',
  userPhone1: {
    value: '',
    label: '',
  },
  userPhone2: '',
  position: {
    value: '',
    label: '',
  },
};

const Mypage = () => {
  const { auth } = useLang();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<MypageProps>({
    defaultValues,
    resolver: yupResolver(mypageSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();
  const model = MypageViewModel(values, setValue);

  return (
    <Layout>
      <MypageStyled isMobile={isMobileWidth}>
        <div className={isMobileWidth ? 'mo-mypage-inner' : 'mypage-inner'}>
          <h2>{auth.editUserInfo}</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  model?.handleUserInfoModify();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='profile'
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? '250' : ''}
                    accept={'image/*'}
                    filename={
                      model?.loginInfo?.files !== null
                        ? model?.loginInfo?.files.file_name
                        : ''
                    }
                    fullWidth={false}
                    placeholder={auth.UploadProfile}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.email}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='password'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.newPassword}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='passwordCheck'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.checkPassword}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='name'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.name}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={auth.selectPosition}
                    variant='primary'
                    fullWidth={true}
                    options={POSITIONS_OPTIONS}
                  />
                )}
              />
            </div>
            <div className='select-wrap'>
              <div className='select-sec'>
                <Controller
                  control={control}
                  name='userPhone1'
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder={auth.select}
                      variant='primary'
                      options={PREFIX_NUMBER_OPTIONS}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='userPhone2'
                  render={({ field }) => (
                    <Input
                      {...field}
                      type='number'
                      multiline={false}
                      fullWidth={false}
                      placeholder={auth.phone}
                      maxLength={8}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.editUserInfo}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.editUserInfo}
              </Button>
            )}
          </form>
        </div>
      </MypageStyled>
    </Layout>
  );
};

const MypageStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  background-color: #fff;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  & .mypage-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mo-mypage-inner {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .select-wrap {
    width: 100%;
    display: flex;
    margin: 10px 0;
  }

  & .select-sec {
    display: flex;
    width: 100%;
  }

  & .send-message {
    width: 120px;
    height: 65px;
    background-color: #1f1852;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 50px 20px 100px 20px;
        `
      : css`
          padding: 50px 20px;
          height: calc(100vh - 80px);
          overflow-y: scroll;
        `}
`;

export default Mypage;
