import { useNavigate } from 'react-router-dom';
import SetErrorBar from 'utils/SetErrorBar';
import { SignUpPayloadProps } from 'utils/Types';
import {
  fetchFileUpload,
  fetchSendEmail,
  fetchSignUp,
  fetchVerificationEmail,
} from 'api/auth';
import { useEffect, useState } from 'react';
import { SignTabTypeEnum } from 'utils/Enum';
import { useSignUpEssentialDataCheck } from 'hooks/useEssentialCheck';
import { useLang } from 'utils/lang/useLang';

export const SignUpViewModel = (
  values: any,
  setValue: (e: any, v: any) => void,
  setError: (e: any, v: any, i: any) => void
) => {
  const { message } = useLang();
  const navigate = useNavigate();
  const [img, setImg] = useState<any>([]);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const [isEmailVerify, setIsEmailVerify] = useState<boolean>(false);
  const [emailTabType, setEmailTabType] = useState<SignTabTypeEnum>(
    SignTabTypeEnum.EMAILABLED
  );
  const [verifyTabType, setVerifyTabType] = useState<SignTabTypeEnum>(
    SignTabTypeEnum.VERIFYABLED
  );

  // 필수항목 체크 훅
  const signUpEssential = useSignUpEssentialDataCheck(values);

  useEffect(() => {
    if (signUpEssential) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [signUpEssential]);

  useEffect(() => {
    setImg(values.profile);
  }, [values.profile]);

  // 회원가입 핸들러
  const handleRegister = async () => {
    setIsLoading(true);
    let attachment: any = [];

    if (img) {
      const file = img[0];
      const fileUploadData = await fetchFileUpload(file);
      attachment.push(fileUploadData.data.result.id);
    }

    const payload: SignUpPayloadProps = {
      user_name: values.email,
      password: values.password,
      name: values.name,
      phone: values.userPhone1.value + values.userPhone2,
      position: values.position.value,
      profile_image_id: attachment[0],
    };
    const signUpData = await fetchSignUp(payload);

    if (signUpData.status === 200) {
      setIsLoading(false);
      navigate('/login', { replace: true });
    } else {
      if (signUpData.response?.data.message === 'duplicated email') {
        SetErrorBar(message.duplicateEmail);
        setIsLoading(false);
        setIsEmail(false);
        setIsEmailVerify(false);
        setValue('email', '');
        setValue('emailVerify', '');
        setEmailTabType(SignTabTypeEnum.EMAILABLED);
        setVerifyTabType(SignTabTypeEnum.VERIFYABLED);
      }
    }
  };

  // 이메일 인증번호 전송 핸들러
  const handleEmailSubmit = async () => {
    if (values.email) {
      const sendEmailData = await fetchSendEmail(values.email);
      if (sendEmailData.status === 200) {
        SetErrorBar(message.sentVerification);
        setIsEmail(true);
        setEmailTabType(SignTabTypeEnum.EMAILDISABLED);
      }
    } else {
      setError(
        'email',
        { type: 'focus', message: message.enterEmail },
        { shouldFocus: true }
      );
    }
  };

  // 이메일 인증 핸들러
  const handleEmailVerify = async () => {
    if (isEmail) {
      const payload = {
        email: values.email,
        key: values.emailVerify,
      };
      const verifyEmailData = await fetchVerificationEmail(payload);

      if (verifyEmailData.status === 200) {
        SetErrorBar(message.verfiedEmail);
        setIsEmailVerify(true);
        setVerifyTabType(SignTabTypeEnum.VERIFYDISABLED);
      } else {
        if (verifyEmailData.response?.data.message === 'key is required') {
          SetErrorBar(message.enterVerfyNumber);
        }
        if (verifyEmailData.response?.data.message === 'not matched') {
          SetErrorBar(message.incorrectVerfyNumber);
        }
      }
    } else {
      SetErrorBar(message.sendVerfyNum);
      setError(
        'email',
        { type: 'focus', message: message.enterEmail },
        { shouldFocus: true }
      );
    }
  };

  return {
    isEmail,
    isLoading,
    isAbledBtn,
    emailTabType,
    verifyTabType,
    isEmailVerify,
    handleRegister,
    handleEmailSubmit,
    handleEmailVerify,
  };
};
