import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SetErrorBar from 'utils/SetErrorBar';
import { ElementProps } from 'utils/Types';

const AuthGuard = ({ children }: ElementProps) => {
  const navigate = useNavigate();

  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    if (!accessToken) {
      SetErrorBar('로그인 후 이용해주세요.');
      navigate('/login', { replace: true });
    }
  }, []);

  if (!accessToken) {
    return null;
  }
  return children;
};

export default AuthGuard;
