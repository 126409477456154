import * as yup from 'yup';

/**
 * @file 로그인 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  userId: yup.string().required('이메일 주소(아이디)를 입력하세요.'),
  userPw: yup.string().required('비밀번호를 입력하세요.'),
});
