import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import { SignProps } from 'utils/Types';
import { SignTabTypeEnum } from 'utils/Enum';
import SetErrorBar from 'utils/SetErrorBar';
import signSchema from 'utils/validation/signSchema';
import { PREFIX_NUMBER_OPTIONS, POSITIONS_OPTIONS } from 'utils/constants';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import FileInput from 'components/FileInput/FileInput';
import { SignUpViewModel } from 'services/SignUpViewModel';
import DimmedSpiner from 'utils/DimmedSpiner';
import { useLang } from 'utils/lang/useLang';

const defaultValues: SignProps = {
  profile: [],
  email: '',
  emailVerify: '',
  password: '',
  passwordCheck: '',
  name: '',
  position: '',
  userPhone1: '',
  userPhone2: '',
};

const Sign = () => {
  const { auth } = useLang();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    setValue,
    handleSubmit,
    setError,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<SignProps>({
    defaultValues,
    resolver: yupResolver(signSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();
  const model = SignUpViewModel(values, setValue, setError);

  return (
    <Layout>
      {model?.isLoading && <DimmedSpiner />}
      <SignStyled isMobile={isMobileWidth}>
        <div className='sign-inner'>
          <h2>{auth.signup}</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  if (model?.isEmailVerify) {
                    model?.handleRegister();
                  } else {
                    SetErrorBar(auth.emailVerfyFirst);
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='profile'
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? '253' : ''}
                    accept={'image/*'}
                    fullWidth={false}
                    errors={errors}
                    placeholder={auth.UploadProfile}
                  />
                )}
              />
            </div>
            <div className='select-wrap'>
              <div className='select-sec'>
                <Controller
                  control={control}
                  name='email'
                  render={({ field }) => (
                    <Input
                      {...field}
                      type='text'
                      multiline={false}
                      fullWidth={false}
                      placeholder={auth.email}
                      errors={errors}
                      disabled={model?.isEmail}
                    />
                  )}
                />
              </div>
              <EmailBtnStyled
                active={model?.emailTabType === SignTabTypeEnum.EMAILDISABLED}
                type='button'
                className='send-email'
                onClick={model?.handleEmailSubmit}
              >
                {auth.send}
              </EmailBtnStyled>
            </div>
            <div className='select-wrap'>
              <div className='select-sec'>
                <Controller
                  control={control}
                  name='emailVerify'
                  render={({ field }) => (
                    <Input
                      {...field}
                      type='text'
                      multiline={false}
                      fullWidth={false}
                      placeholder={auth.emailVerifyNumber}
                      maxLength={6}
                      errors={errors}
                      disabled={model?.isEmailVerify}
                    />
                  )}
                />
              </div>
              <EmailBtnStyled
                active={model?.verifyTabType === SignTabTypeEnum.VERIFYDISABLED}
                type='button'
                className='send-email'
                onClick={model?.handleEmailVerify}
              >
                {auth.emailVerify}
              </EmailBtnStyled>
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='password'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.password}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='passwordCheck'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.checkPassword}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='name'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.name}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={auth.selectPosition}
                    variant='primary'
                    fullWidth={true}
                    options={POSITIONS_OPTIONS}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='select-wrap'>
              <div className='select-sec'>
                <Controller
                  control={control}
                  name='userPhone1'
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder={auth.select}
                      variant='primary'
                      options={PREFIX_NUMBER_OPTIONS}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='userPhone2'
                  render={({ field }) => (
                    <Input
                      {...field}
                      type='number'
                      multiline={false}
                      fullWidth={false}
                      placeholder={auth.phone}
                      maxLength={8}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.signup}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.signup}
              </Button>
            )}
          </form>
        </div>
      </SignStyled>
    </Layout>
  );
};

const SignStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  background-color: #fff;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  & .sign-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .select-wrap {
    width: 100%;
    display: flex;
    margin: 10px 0;
  }

  & .select-sec {
    display: flex;
    width: 100%;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 50px 20px 100px 20px;
        `
      : css`
          padding: 50px 20px;
          height: calc(100vh - 80px);
          overflow-y: scroll;
        `}
`;

const EmailBtnStyled = styled.button<{ active: boolean }>`
  width: 120px;
  height: 65px;
  background-color: #1f1852;
  color: #fff;
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          background-color: gray;
        `
      : css`
          background-color: #1f1852;
        `}
`;

export default Sign;
