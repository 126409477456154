import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GET_PARAMS_ENUM } from 'utils/Enum';
import { fetchPortfolioList } from 'api/portfolio';

export const WorkViewModel = () => {
  const [serchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [resultData, setResultData] = useState<any[]>([]);
  const queryString: any = serchParams.get(GET_PARAMS_ENUM.TYPE);
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    handlePortfolioList();
  }, [page, queryString]);

  // 포트폴리오 리스트 핸들러
  const handlePortfolioList = async () => {
    const payload = { page: page - 1, limit: 9, type: queryString };
    const portfolioData = await fetchPortfolioList(payload);

    if (portfolioData.result) {
      setResultData(portfolioData.result.data);
      setTotalCount(portfolioData.result.total_elements);
      setTotalPages(Math.ceil(portfolioData.result.total_elements / 9));
    }
  };

  return {
    accessToken,
    resultData,
    page,
    totalPages,
    totalCount,
    queryString,
    setPage,
  };
};
