import { useTranslation } from "react-i18next";

export const useLang = () => {
  const { t } = useTranslation();
  return {
    common: {
      confirm: `${t("confirm")}`,
      cancel: `${t("cancel")}`,
      submit: `${t("submit")}`,
      edit: `${t("edit")}`,
      delete: `${t("delete")}`,
      file: `${t("file")}`,
      total: `${t("total")}`,
    },
    auth: {
      login: `${t("login")}`,
      logout: `${t("logout")}`,
      signup: `${t("signup")}`,
      email: `${t("email")}`,
      password: `${t("password")}`,
      newPassword: `${t("newPassword")}`,
      checkPassword: `${t("checkPassword")}`,
      name: `${t("name")}`,
      phone: `${t("phone")}`,
      autoLogin: `${t("autoLogin")}`,
      rememberId: `${t("rememberId")}`,
      findPassword: `${t("findPassword")}`,
      changePassword: `${t("changePassword")}`,
      updateUserInfo: `${t("updateUserInfo")}`,
      welcome: `${t("welcome")}`,
      editUserInfo: `${t("editUserInfo")}`,
      adminPage: `${t("adminPage")}`,
      changeLang: `${t("changeLang")}`,
      send: `${t("send")}`,
      select: `${t("select")}`,
      selectPosition: `${t("selectPosition")}`,
      emailVerify: `${t("emailVerify")}`,
      UploadProfile: `${t("UploadProfile")}`,
      emailVerifyNumber: `${t("emailVerifyNumber")}`,
      emailVerfyFirst: `${t("emailVerfyFirst")}`,
      attachResume: `${t("attachResume")}`,
    },
    project: {
      projectName: `${t("projectName")}`,
      projectEdit: `${t("projectEdit")}`,
      outline: `${t("outline")}`,
      contents: `${t("contents")}`,
      projectImage: `${t("projectImage")}`,
      noProjects: `${t("noProjects")}`,
      registerProject: `${t("registerProject")}`,
    },
    friendsManage: {
      approval: `${t("approval")}`,
      reject: `${t("reject")}`,
      add: `${t("add")}`,
      delete: `${t("delete")}`,
      confirm: `${t("confirm")}`,
      friendManage: `${t("friendManage")}`,
      memberList: `${t("memberList")}`,
      requestReceived: `${t("requestReceived")}`,
      addFriend: `${t("addFriend")}`,
      deleteFriend: `${t("deleteFriend")}`,
      requestFriend: `${t("requestFriend")}`,
      requestingFriend: `${t("requestingFriend")}`,
      goChatting: `${t("goChatting")}`,
      enterSearchKeyword: `${t("enterSearchKeyword")}`,
      wouldFriendRequest: `${t("wouldFriendRequest")}`,
      wouldAddFriend: `${t("wouldAddFriend")}`,
      wouldDeclineFriend: `${t("wouldDeclineFriend")}`,
      wouldDeleteFriend: `${t("wouldDeleteFriend")}`,
      friendAdded: `${t("friendAdded")}`,
      deleted: `${t("deleted")}`,
      refused: `${t("refused")}`,
    },
    chatting: {
      chat: `${t("chat")}`,
      friend: `${t("friend")}`,
      chatStart: `${t("chatStart")}`,
      nameSearch: `${t("nameSearch")}`,
      sendImage: `${t("sendImage")}`,
      replyTo: `${t("replyTo")}`,
      chatroolSetting: `${t("chatroolSetting")}`,
      notiSetting: `${t("notiSetting")}`,
      chatroolLeave: `${t("chatroolLeave")}`,
      exit: `${t("exit")}`,
      wouldChatroolLeave: `${t("wouldChatroolLeave")}`,
    },
    notify: {
      noti: `${t("noti")}`,
      unreadNoti: `${t("unreadNoti")}`,
      noNotifications: `${t("noNotifications")}`,
    },
    message: {
      invalidUserName: `${t("invalidUserName")}`,
      invalidPassword: `${t("invalidPassword")}`,
      duplicateEmail: `${t("duplicateEmail")}`,
      sentVerification: `${t("sentVerification")}`,
      enterEmail: `${t("enterEmail")}`,
      verfiedEmail: `${t("verfiedEmail")}`,
      enterVerfyNumber: `${t("enterVerfyNumber")}`,
      incorrectVerfyNumber: `${t("incorrectVerfyNumber")}`,
      sendVerfyNum: `${t("sendVerfyNum")}`,
      linkChangePw: `${t("linkChangePw")}`,
      emailNotFound: `${t("emailNotFound")}`,
      isChangedPassword: `${t("isChangedPassword")}`,
      updatedMember: `${t("updatedMember")}`,
      editedResume: `${t("editedResume")}`,
      deletePost: `${t("deletePost")}`,
      approvalRegister: `${t("approvalRegister")}`,
      approvalModified: `${t("approvalModified")}`,
      approvalDeleted: `${t("approvalDeleted")}`,
    },
  };
};
