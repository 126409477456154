/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ErrorMessage } from '@hookform/error-message';
import { ERROR_COLOR } from 'utils/constants';
import { ErrorProps } from 'utils/Types';

const ErrorsMessage = (props: ErrorProps) => {
  const { errors, name } = props;

  const root = errorMessage();

  return (
    <>
      {errors?.[name] && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={() => <p css={root}>{`${errors[name].message}`}</p>}
        />
      )}
    </>
  );
};

const errorMessage = () => {
  return css`
    color: ${ERROR_COLOR};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
  `;
};

export default ErrorsMessage;
