import { fetchFindPassword } from 'api/auth';
import { useEffect, useState } from 'react';
import { useLang } from 'utils/lang/useLang';
import SetErrorBar from 'utils/SetErrorBar';

export const SearchAuthViewModel = (values: any) => {
  const { message } = useLang();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);

  useEffect(() => {
    if (values.email !== '') {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.email]);

  // 비밀번호 변경 요청 핸들러
  const handleSearchAuth = async () => {
    const findPasswordData = await fetchFindPassword(values.email);

    if (findPasswordData.status === 200) {
      SetErrorBar(message.linkChangePw);
    } else {
      if (findPasswordData.response?.data.message === 'user not found') {
        SetErrorBar(message.emailNotFound);
      }
    }
  };

  return { isAbledBtn, handleSearchAuth };
};
