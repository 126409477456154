import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Document, Page, pdfjs } from 'react-pdf';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from 'layout/Layout';
import { ResumeViewModel } from 'services/ResumeViewModel';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Resume = () => {
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const model = ResumeViewModel();

  return (
    <Layout>
      <ResumeStyled isMobile={isMobileWidth}>
        <div className='resume-wrap'>
          <div className='page'>
            <div className='page-text'>
              Page {model?.pageNumber} of {model?.numPages}
            </div>
            <div>
              <button
                onClick={() => {
                  model?.setPageNumber(
                    model?.pageNumber === 1
                      ? model?.pageNumber
                      : model?.pageNumber - 1
                  );
                }}
              >
                &lt;
              </button>
              <button
                onClick={() => {
                  model?.setPageNumber(
                    model?.numPages === model?.pageNumber
                      ? model?.pageNumber
                      : model?.pageNumber + 1
                  );
                }}
              >
                &gt;
              </button>
            </div>
          </div>
          {model?.accessToken && model?.pdfFilePath?.raw_files.server_path && (
            <div className='btn-group'>
              <div className='registBtn'>
                <button
                  onClick={() => {
                    navigate('/adminpage', {
                      state: {
                        isUpdate: true,
                        resumeData: model?.pdfFilePath,
                      },
                    });
                  }}
                >
                  수정
                </button>
              </div>
              <div className='registBtn deleteBtn'>
                <button
                  onClick={() => {
                    const result = window.confirm('이력서를 삭제하시겠습니까?');
                    if (result) {
                      model?.handleDeleteResume();
                    }
                  }}
                >
                  삭제
                </button>
              </div>
            </div>
          )}
        </div>
        <div className='pdf'>
          <Document
            file={
              model?.pdfFilePath &&
              `https://portfolio-buket.s3.ap-northeast-2.amazonaws.com${model?.pdfFilePath?.raw_files.server_path}`
            }
            onLoadSuccess={model?.onDocumentLoadSuccess}
          >
            <Page
              width={isMobileWidth ? 350 : 1000}
              scale={model?.pageScale}
              pageNumber={model?.pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      </ResumeStyled>
    </Layout>
  );
};

const ResumeStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  overflow-y: scroll;

  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .page div {
    margin-right: 20px;
  }
  .page button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    margin: 0 5px;
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #fff;
  }

  & .btn-group {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  }

  & .registBtn {
    display: flex;
    margin-right: 15px;

    button {
      cursor: pointer;
      width: 80px;
      height: 35px;
      color: #fff;
      border-radius: 4px;
      background-color: #1f1852;
    }
  }

  & .deleteBtn button {
    background-color: #e40000;
  }

  .pdf {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 10px 0 0 0;
          .resume-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .page {
            margin-left: 10px;
          }
        `
      : css`
          padding: 20px 0 30px 20px;
          .page {
            position: fixed;
            z-index: 9999999;
            left: 62%;
            bottom: 50px;
            transform: translateX(-50%);
          }
          .page-text {
            background-color: #fff;
          }
        `}
`;

export default Resume;
