import request from './request';
import { SendMessageProps, SetChatRoomConfigProps } from 'utils/Types';

// 채팅방 메세지 리스트
const chatList = async (chatRoomId: string) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}`,
      'get',
      { 'content-type': 'application/json' },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 채팅방 멤버 리스트
const chatMemberList = async (chatRoomId: string) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}/members`,
      'get',
      { 'content-type': 'application/json' },
      {
        chatRoomId: chatRoomId,
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 메세지 전송
const sendMessage = async ({ chatRoomId, payload }: SendMessageProps) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}`,
      'post',
      { 'content-type': 'application/json' },
      payload
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 채팅방 설정 가져오기
const chatRoomConfig = async (chatRoomId: string) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}/configs`,
      'get',
      { 'content-type': 'application/json' },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 채팅방 설정 변경하기
const setChatRoomConfig = async ({
  chatRoomId,
  payload,
}: SetChatRoomConfigProps) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}/configs`,
      'put',
      { 'content-type': 'application/json' },
      payload
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  chatList,
  chatMemberList,
  sendMessage,
  chatRoomConfig,
  setChatRoomConfig,
};
