/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BoxProps } from "utils/Types";

const Box = (props: BoxProps) => {
  const { data, onClick } = props;

  const root = rootCss(props);

  return (
    <div css={root} onClick={onClick}>
      <div className="image-box" />
      <p className="text">{data.title}</p>
    </div>
  );
};

const rootCss = (props: BoxProps) => {
  return css`
    width: ${`${props.w}%`};
    margin: 10px;
    text-align: center;

    & .image-box {
      overflow: hidden;
      height: 300px;
      border-radius: 8px;
      cursor: pointer;
      border: 3px solid #d9d9d9;
      background-size: cover;
      background-position: center;
      background-image: ${`url(${process.env.REACT_APP_API}/files/${props.data.attachments[0]})`};
    }

    & .text {
      margin-top: 10px;
    }
  `;
};

export default Box;
