import { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import Layout from 'layout/Layout';
import { FriendTabTypeEnum } from 'utils/Enum';
import FriendSideBar from './FriendSideBar';
import FriendListTable from './FriendListTable';
import MobileFriendListTable from './MobileFriendListTable';
import { FriendsViewModel } from 'services/FriendsViewModel';
import { useLang } from 'utils/lang/useLang';

const Friends = () => {
  const { friendsManage } = useLang();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [selectedFriendInfo, setSelectedFriendInfo] = useState<any>(null);
  const [tabType, setTabType] = useState<FriendTabTypeEnum>(
    FriendTabTypeEnum.DEFAULT
  );
  const model = FriendsViewModel();

  return (
    <Layout>
      <FriendsStyled isMobile={isMobileWidth}>
        <h2>{friendsManage.friendManage}</h2>
        <div className='member-wrap'>
          <div className='sidebar-sec'>
            <FriendSideBar
              data={model?.friendsData}
              selectedFriendInfo={selectedFriendInfo}
              setSelectedFriendInfo={setSelectedFriendInfo}
              mutate={model?.handleFriendsList}
              friendRequests={
                model?.isApproved?.length > 0 ? [] : model?.friendRequests
              }
              requestsMutate={model?.handleFriendsRequest}
              tabType={tabType}
              setTabType={setTabType}
            />
          </div>
          {isMobileWidth ? (
            <div>
              <MobileFriendListTable
                data={selectedFriendInfo}
                setSelectedFriendInfo={setSelectedFriendInfo}
                mutate={model?.handleFriendsList}
                requestsRefetch={model?.handleFriendsRequest}
                setTabType={setTabType}
              />
            </div>
          ) : (
            <div className='right-sec'>
              <FriendListTable
                data={selectedFriendInfo}
                setSelectedFriendInfo={setSelectedFriendInfo}
                mutate={model?.handleFriendsList}
                requestsRefetch={model?.handleFriendsRequest}
                setTabType={setTabType}
              />
            </div>
          )}
        </div>
      </FriendsStyled>
    </Layout>
  );
};

const FriendsStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 20px 0 60px 20px;
  overflow-y: scroll;

  h2 {
    display: block;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.36px;
    text-align: left;
    color: #222;
    margin-bottom: 20px;
  }

  & .member-wrap {
    display: flex;
    flex-direction: row;
    background-color: #fff;

    box-shadow: 7px 10px 30px 1px #d9d9d9;
  }

  & .sidebar-sec {
    width: 360px;
  }

  & .right-sec {
    width: 100%;
    height: 720px;
    max-height: 720px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e3e3e3;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          .member-wrap {
            margin-right: 40px;
          }
        `
      : css`
          .member-wrap {
            margin-right: 40px;
          }
        `}
`;

export default Friends;
