import { forwardRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import ErrorsMessage from 'components/ErrorsMessage/ErrorsMessage';
import DropdownSVG from 'assets/images/icon_dropdewn.svg';
import { SelectProps } from 'utils/Types';

const primaryStyles = {
  control: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : '150px';
    const borderColor = state.hasValue ? '#707070' : '#dddddd';

    return {
      ...provided,
      width: state.selectProps.fullWidth ? '100%' : width,
      height: '65px',
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : '#fff',
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? '#707070' : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: 'none',
      marginRight: '10px',
    };
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/15px`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : '150px';
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? '100%' : width}`,
      backgroundColor: '#fff',
      marginTop: 4,
      boxShadow: 'none',
      borderRadius: 0,
      border: '1px solid #707070',
    };
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: '16px',
      textAlign: 'left',
      color: state.isSelected ? '#000' : '#b2b2b2',
      background: state.isFocused ? '#fafafa' : '#fff',
      cursor: 'pointer',
    };
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      fontSize: '16px',
      textAlign: 'left',
      color: state.selectProps.color ? state.selectProps.color : '#000',
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 20,
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginLeft: 0,
      fontSize: '16px',
      textAlign: 'left',
      color: state.selectProps.color ? state.selectProps.color : '#000',
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: 'none' }),
};

const Arrow = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div />
    </components.DropdownIndicator>
  );
};

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    options,
    placeholder,
    defaultValue,
    isSearchable = false,
    variant,
    errors,
    ...rest
  } = props;

  const defaultOption: number | null =
    defaultValue && options
      ? [...options].map((x: any) => x.value).indexOf(defaultValue)
      : 0;

  return (
    <>
      <div>
        <ReactSelect
          {...rest}
          className='component-select'
          placeholder={placeholder}
          defaultValue={options[defaultOption]}
          components={{
            DropdownIndicator: Arrow,
          }}
          options={options}
          styles={(() => {
            switch (variant) {
              case 'primary':
                return primaryStyles;
              default:
                return primaryStyles;
            }
          })()}
          isSearchable={isSearchable}
        />
        {errors && <ErrorsMessage errors={errors} name={props.name} />}
      </div>
    </>
  );
});

export default Select;
