import { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { AlertCasesEnum } from 'utils/Enum';
import { useLang } from 'utils/lang/useLang';
import { AlertMessageViewModel } from 'services/AlertMessageViewModel';

const AlertMessage = ({ alertsData, handleAlerts }: any) => {
  const { notify } = useLang();
  const [alertTitle, setAlertTitle] = useState('readed');
  const root = rootCss(alertTitle);
  const model = AlertMessageViewModel(alertsData, handleAlerts);

  return (
    <div css={root}>
      <div className='rootInner'>
        <div className='alert-header'>
          <ul className='alert-title'>
            <li
              className='readed-alert'
              onClick={() => {
                setAlertTitle('readed');
              }}
            >
              {notify.noti}
            </li>
            <li
              className='unreaded-alert'
              onClick={() => {
                setAlertTitle('unreaded');
              }}
            >
              {notify.unreadNoti}
              {model?.unreadAlerts?.length > 0 ? <NotificationDot /> : null}
            </li>
          </ul>
        </div>
        <div className='alert-box'>
          {alertsData.length === 0 && (
            <div className='no-alert'>{notify.noNotifications}</div>
          )}
          {alertTitle === 'readed' &&
            alertsData.map((v: any, i: number) => {
              return (
                <div
                  key={i}
                  className='alert-wrap'
                  onClick={() =>
                    model?.handleUpdateAlert(v.id, v.is_readed, v.case, v)
                  }
                >
                  <div className='alert-inner'>
                    <Badge active={v?.is_readed === false}>
                      {
                        AlertCasesEnum?.[
                          v.case as unknown as keyof typeof AlertCasesEnum
                        ]
                      }
                    </Badge>
                    <span className='createdat'>
                      {dayjs(v.created_at).format('MM.DD HH:mm')}
                    </span>
                  </div>
                  <div className='alert-bottom'>
                    <span className='alert-message'>{v.message}</span>
                  </div>
                </div>
              );
            })}
          {alertTitle === 'unreaded' &&
            alertsData.map((v: any, i: number) => {
              return (
                v.is_readed === false && (
                  <div
                    key={i}
                    className='alert-wrap'
                    onClick={() =>
                      model?.handleUpdateAlert(v.id, v.is_readed, v.case, v)
                    }
                  >
                    <div className='alert-inner'>
                      <Badge active={v?.is_readed === false}>
                        {
                          AlertCasesEnum?.[
                            v.case as unknown as keyof typeof AlertCasesEnum
                          ]
                        }
                      </Badge>
                      <span className='createdat'>
                        {dayjs(v.created_at).format('MM.DD HH:mm')}
                      </span>
                    </div>
                    <div className='alert-bottom'>
                      <span className='alert-message'>{v.message}</span>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </div>
  );
};

const rootCss = (alertTitle: string) => {
  return css`
    position: absolute;
    z-index: 999999999;
    top: 80px;
    right: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px #d9d9d9;

    .rootInner {
      width: 355px;
      min-height: 500px;
    }

    .rootInner::-webkit-scrollbar {
      display: none;
    }

    .alert-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .alert-title {
      display: flex;
      margin-bottom: 10px;
      li {
        margin: 20px 0 0 20px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }

      .readed-alert {
        color: ${alertTitle === 'readed' ? '#000' : '#d1d1d1'};
      }

      .unreaded-alert {
        color: ${alertTitle === 'unreaded' ? '#000' : '#d1d1d1'};
      }
    }

    .no-alert {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .alert-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: white;
      height: 100%;
      max-height: 500px;
      padding-bottom: 30px;
      overflow-y: scroll;
      overflow-y: overlay;
    }

    .alert-wrap {
      min-height: 74px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2;
      padding: 12px 20px;
      cursor: pointer;
    }

    .alert-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .alert-bottom {
      left: auto;
      margin-top: 8px;
    }

    .badge {
    }

    .alert-message {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
    }

    .createdat {
      font-size: 13px;
      letter-spacing: -0.26px;
      color: #999;
      margin-left: auto;
      min-width: 66px;
    }
  `;
};

const Badge = styled.div<{ active?: boolean; hidden?: boolean }>`
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 50px;
  padding: 4px 10px 3px 11px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.24px;

  margin-right: 10px;

  ${({ active, hidden }) =>
    active
      ? css`
          background-color: #ff7d00;
          color: #fff;
        `
      : hidden
      ? css`
          background-color: #e3e3e3;
          color: #999;
        `
      : css`
          background-color: #f2f2f2;
          color: #444;
        `}
`;

const NotificationDot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #ff5517;
  top: 20px;
  margin-left: -5px;
`;

export default AlertMessage;
