import * as yup from 'yup';

export default yup.object().shape({
  profile: yup
    .mixed()
    .required('A file is required')
    .test('fileFormat', '프로필 이미지를 첨부해주세요.', (value) => {
      const accept = value[0]?.name.split('.')[1].toLowerCase();
      return value && ['jpg', 'jpeg', 'png'].includes(accept);
    }),
  email: yup
    .string()
    .required('이메일 주소를 입력하세요.')
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      '이메일 주소 형식이 올바르지 않습니다.'
    ),
  emailVerify: yup.string().required('인증번호를 입력하세요.'),
  password: yup
    .string()
    .required('대소문자/숫자/특수문자를 조합, 8~15자를 입력하세요.')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      '알파벳, 숫자, 공백을 제외한 특수문자를 모두 포함한 8~15자를 입력하세요'
    ),
  passwordCheck: yup
    .string()
    .oneOf([yup.ref('password'), null], '비밀번호와 동일한 문자를 입력하세요.')
    .required('비밀번호와 동일한 문자를 입력하세요.'),
  name: yup.string().required('이름을 입력하세요.'),
  position: yup
    .mixed()
    .required('포지션을 선택하세요.')
    .test('position', '포지션을 선택하세요.', (value) => {
      return value && ['프론트엔드', '백엔드', '풀스텍'].includes(value?.value);
    }),
  userPhone2: yup.string().required('핸드폰 번호를 입력하세요.'),
});
