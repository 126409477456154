import request from './request';
import {
  LoginResult,
  PermissionResult,
  SuccessResult,
  UserInfoResult,
} from 'utils/ResultTypes';
import {
  LoginPayloadProps,
  SignUpPayloadProps,
  VerificationEmailPayloadProps,
} from 'utils/Types';

// 회원가입
export const fetchSignUp = async (
  payload: SignUpPayloadProps
): Promise<SuccessResult> => {
  try {
    const result = await request('/auth/register', 'post', {}, payload);

    return result;
  } catch (error: any) {
    return error;
  }
};

// 로그인
export const fetchLogin = async (
  payload: LoginPayloadProps
): Promise<LoginResult> => {
  try {
    const result = await request('/auth/login', 'post', {}, payload);

    return result;
  } catch (error: any) {
    return error;
  }
};

// 로그아웃
export const logout = () => {
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  targetStorage.removeItem('accessToken');
  targetStorage.removeItem('refreshToken');
};

// 유저 정보 가져오기
export const fetchUserInfo = async (): Promise<UserInfoResult> => {
  try {
    const result = await request('/auth/login', 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 로그인 정보 가져오기
export const fetchGetLoginedUserInfo = async (userId: number) => {
  try {
    const result = await request(`/users/${userId}`, 'get', {}, { userId });
    return result;
  } catch (error: any) {
    return error;
  }
};

// 회원정보 수정
export const fetchUpdateUserInfo = async (
  payload: any
): Promise<LoginResult> => {
  try {
    const result = await request(`/auth/login`, 'put', {}, payload);
    return result;
  } catch (error: any) {
    return error;
  }
};

// 유저 권한 관련
export const fetchPermission = async (
  userId: number
): Promise<PermissionResult> => {
  try {
    const result = await request('/permissions', 'get', {}, { id: userId });

    return result;
  } catch (error: any) {
    return error;
  }
};

// 비밀번호 찾기
export const fetchFindPassword = async (
  email: string
): Promise<SuccessResult> => {
  try {
    const result = await request('/auth/find-password', 'post', {}, { email });

    return result;
  } catch (error: any) {
    return error;
  }
};

// 비밀번호 변경
export const fetchChangePassword = async (
  key: string,
  password: string
): Promise<SuccessResult> => {
  try {
    const result = await request(
      `/auth/find-password/${key}`,
      'post',
      {},
      { password }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};

// 이메일 검증 번호 전송
export const fetchSendEmail = async (email: string): Promise<SuccessResult> => {
  try {
    const result = await request('verifications/email', 'post', {}, { email });
    return result;
  } catch (error: any) {
    return error;
  }
};

// 이메일 검증
export const fetchVerificationEmail = async (
  payload: VerificationEmailPayloadProps
): Promise<SuccessResult> => {
  try {
    const result = await request(
      'verifications/email/verify',
      'post',
      {},
      payload
    );

    return result;
  } catch (error: any) {
    return error;
  }
};

// 파일 업로드
export const fetchFileUpload = async (file: any) => {
  try {
    const result = await request(
      '/files',
      'post',
      { 'Content-Type': 'multipart/form-data' },
      { file }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};
