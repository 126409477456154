import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import adminpageSchema from 'utils/validation/adminpageSchema';
import Button from 'components/Button/Button';
import FileInput from 'components/FileInput/FileInput';
import { ArrayProps } from 'utils/Types';
import { AdminPageViewModel } from 'services/AdminPageViewModel';
import { useLang } from 'utils/lang/useLang';

const defaultValues: ArrayProps = {
  resume: [],
};

const AdminPage = () => {
  const { auth } = useLang();
  const location = useLocation();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<ArrayProps>({
    defaultValues,
    resolver: yupResolver(adminpageSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();
  const model = AdminPageViewModel(values);

  return (
    <Layout>
      <AdminpageStyled>
        <div className={isMobileWidth ? 'mo-mypage-inner' : 'mypage-inner'}>
          <h2>{auth.adminPage}</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  if (location?.state.isUpdate) {
                    model?.handleResumeModify();
                  } else {
                    model?.handleUserInfoModify();
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='resume'
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? '250' : ''}
                    accept={'.pdf'}
                    filename={
                      location.state.resumeData !== undefined
                        ? location.state.resumeData.raw_files.file_name
                        : ''
                    }
                    fullWidth={false}
                    placeholder={auth.attachResume}
                    errors={errors}
                  />
                )}
              />
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.updateUserInfo}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.updateUserInfo}
              </Button>
            )}
          </form>
        </div>
      </AdminpageStyled>
    </Layout>
  );
};

const AdminpageStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 50px 20px;
  overflow-y: scroll;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  & .mypage-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mo-mypage-inner {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default AdminPage;
