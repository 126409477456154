import { fetchFileUpload, fetchGetLoginedUserInfo } from 'api/auth';
import { fetchPostResume, fetchUpdateResume } from 'api/resume';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLang } from 'utils/lang/useLang';
import SetErrorBar from 'utils/SetErrorBar';

export const AdminPageViewModel = (values: any) => {
  const { message } = useLang();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useSelector((s: any) => s.auth);
  const [pdf, setPdf] = useState<any>([]);
  const [loginInfo, setLoginInfo] = useState<any>();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);

  useEffect(() => {
    if (userId !== 0) handleUserInfo();
  }, [userId]);

  useEffect(() => {
    if (values.resume.length > 0) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.resume]);

  useEffect(() => {
    setPdf(values.resume);
  }, [values.resume]);

  const handleUserInfo = async () => {
    const userInfoData = await fetchGetLoginedUserInfo(userId);

    if (userInfoData) {
      setLoginInfo(userInfoData.result);
    }
  };

  // 이력서 등록 핸들러
  const handleUserInfoModify = async () => {
    let pdfAttachment: any = [];

    if (pdf.length > 0) {
      const pdffile = pdf[0];
      const fileUploadData = await fetchFileUpload(pdffile);
      pdfAttachment.push(fileUploadData.data.result.id);
    }

    const resumeData = await fetchPostResume(pdfAttachment[0]);

    if (resumeData.status === 200) {
      navigate('/', { replace: true });
      SetErrorBar(message.updatedMember);
    }
  };

  // 이력서 수정 핸들러
  const handleResumeModify = async () => {
    let pdfAttachment: any = [];

    if (pdf.length > 0) {
      const pdffile = pdf[0];
      const fileUploadData = await fetchFileUpload(pdffile);
      pdfAttachment.push(fileUploadData.data.result.id);
    }

    const updateResumeData = await fetchUpdateResume(
      location?.state.resumeData.raw_files.id,
      pdfAttachment[0]
    );

    if (updateResumeData.status === 200) {
      navigate('/resume', { replace: true });
      SetErrorBar(message.editedResume);
    }
  };

  return { pdf, isAbledBtn, handleUserInfoModify, handleResumeModify };
};
