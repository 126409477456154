import { createSlice } from '@reduxjs/toolkit';
import { UiStateType } from 'utils/Types';

export const initialState: UiStateType = {
  currentPosition: 'Home',
  isMobileWidth: false,
};

export const ulSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUiValue: (state, action) => {
      state.currentPosition = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobileWidth = action.payload;
    },
  },
});
export const { setUiValue, setIsMobile } = ulSlice.actions;

export default ulSlice.reducer;
