import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ProfilesImage from 'components/Profile/ProfilesImage';
import { FriendCardProps } from 'utils/Types';

const FriendCard = ({
  info,
  id,
  name,
  active,
  position,
  revalidate,
  isHide,
  onClick,
}: FriendCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container isActive={active} isOpen={isOpen}>
      <InfoContainer onClick={onClick}>
        <InfoWrap>
          <ProfilesImage
            width={48}
            height={48}
            fileId={info?.profile_image_id}
            containerStyle={{
              border: 'solid 1px #e3e3e3',
              borderRadius: '100px',
              marginRight: '14px',
            }}
          />
        </InfoWrap>
        <PeopleCountWrap>
          <Title>{name}</Title>
          <Position>{position}</Position>
        </PeopleCountWrap>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div<{
  isActive: boolean;
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  min-height: 88px;
  border-radius: 12px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
  background-color: white;
  user-select: none;
  border: solid 3px #1f1852;
  margin-bottom: 16px;
  justify-content: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border-color: #1f1852;
        `
      : css`
          border-color: white;
        `}

  &:hover {
    border-color: #1f1852;
  }

  transition: border 0.1s linear, min-height 0.05s linear;

  overflow: hidden;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 20px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  text-align: left;
  color: #222;

  margin-right: 4px;

  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Position = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #444;
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeopleCountWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FriendCard;
