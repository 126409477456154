import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setIsChat } from 'store/chat';
import { setChatTitle } from 'store/chat';
import { setSelectedUserInfo } from 'store/chat';
import Button from 'components/Button/Button';
import ImgModal from 'components/ImgModal/ImgModal';
import TextModal from 'components/TextModal/TextModal';
import ProfilesImage from 'components/Profile/ProfilesImage';
import IcPhoneBkSVG from 'assets/images/ic-phone-bk.svg';
import IcEmailBkSVG from 'assets/images/ic-email-bk.svg';
import IcCellphoneBkSVG from 'assets/images/ic-cellphone-bk.svg';
import { FriendListTableProps } from 'utils/Types';
import { FriendListViewModel } from 'services/FriendListViewModel';
import { useLang } from 'utils/lang/useLang';

const FriendListTable = ({
  data,
  mutate,
  requestsRefetch,
  setSelectedFriendInfo,
  setTabType,
  me,
}: FriendListTableProps) => {
  const { friendsManage } = useLang();
  const dispatch = useDispatch();
  const model = FriendListViewModel(
    data,
    mutate,
    requestsRefetch,
    setTabType,
    setSelectedFriendInfo
  );

  return (
    <MemberListContainer>
      {data && (
        <MemberCell>
          <CellLeftSection>
            <ProfilesImage
              width={80}
              height={80}
              fileId={data?.profile_image_id}
              containerStyle={{
                border: 'solid 1px #e3e3e3',
                borderRadius: '100px',
              }}
            />
            <MemberRow>
              <MemberInfoWrap>
                <CompanyInfo>{data?.company?.name}</CompanyInfo>
                <NameInfo>{data?.name}</NameInfo>
                <PositionInfo>{data?.position}</PositionInfo>
              </MemberInfoWrap>
              {me?.id !== data.id && !model?.getRequest ? (
                <MemberButtonWrap>
                  <Button
                    variant=''
                    preset={0}
                    type='button'
                    w={'100'}
                    h={'48'}
                    fSize={'14'}
                    disabled={model?.isFriend_request}
                    color={model?.isFriend ? 'red' : ''}
                    onClick={() =>
                      model?.isFriend
                        ? model?.setIsDeleteModalOpen(true)
                        : model?.setIsApprovalModalOpen(true)
                    }
                  >
                    {model?.isFriend_request
                      ? friendsManage.requestingFriend
                      : model?.isFriend
                      ? friendsManage.deleteFriend
                      : friendsManage.requestFriend}
                  </Button>
                  <span></span>
                  <Button
                    variant=''
                    preset={0}
                    type='button'
                    w={'100'}
                    h={'48'}
                    fSize={'14'}
                    onClick={() => {
                      dispatch(setSelectedUserInfo(data));
                      dispatch(setIsChat(true));
                      dispatch(setChatTitle('c'));
                    }}
                  >
                    {friendsManage.goChatting}
                  </Button>
                </MemberButtonWrap>
              ) : null}
            </MemberRow>
            <MemberRow>
              <MemberContactWrap>
                <ContactInfo>
                  <Icon src={IcCellphoneBkSVG} />
                  {model?.addHyphen(data?.phone)}
                </ContactInfo>
                <ContactInfo>
                  <Icon src={IcPhoneBkSVG} />
                  {model?.addHyphen(data?.tel)}
                </ContactInfo>
                <ContactInfo>
                  <Icon src={IcEmailBkSVG} />
                  {data?.user_name}
                </ContactInfo>
              </MemberContactWrap>
            </MemberRow>
          </CellLeftSection>
          {model?.getRequest ? (
            <AuthorityButtonWrap>
              <Button
                variant=''
                preset={0}
                type='button'
                w={'100'}
                h={'48'}
                fSize={'14'}
                onClick={() => model?.setIsApprovalModalOpen(true)}
              >
                {friendsManage.approval}
              </Button>
              <span></span>
              <Button
                variant=''
                preset={0}
                type='button'
                w={'100'}
                h={'48'}
                fSize={'14'}
                disabled
                onClick={() => model?.setIsRefuseModalOpen(true)}
              >
                {friendsManage.reject}
              </Button>
            </AuthorityButtonWrap>
          ) : null}
        </MemberCell>
      )}
      <TextModal
        open={model?.isApprovalModalOpen}
        onClose={() => model?.setIsApprovalModalOpen(false)}
        onSubmit={() => model?.handleApproveFriend()}
        content={
          model?.getRequest
            ? friendsManage.wouldFriendRequest
            : friendsManage.wouldAddFriend
        }
        submitText={
          model?.getRequest ? friendsManage.approval : friendsManage.add
        }
      />
      <TextModal
        open={model?.isRefuseModalOpen}
        onClose={() => model?.setIsRefuseModalOpen(false)}
        onSubmit={() => model?.handleRefuseFriend()}
        content={friendsManage.wouldDeclineFriend}
        submitText={friendsManage.reject}
      />
      <TextModal
        open={model?.isDeleteModalOpen}
        onClose={() => model?.setIsDeleteModalOpen(false)}
        onSubmit={() => model?.handleDeleteFriend()}
        content={friendsManage.wouldDeleteFriend}
        submitText={friendsManage.delete}
      />
      <ImgModal
        open={model?.confirmApproveModal}
        onClose={() => model?.setConfirmApproveModal(false)}
        content={friendsManage.friendAdded}
        submitText={friendsManage.confirm}
      />
      <ImgModal
        open={model?.confirmDeleteMember}
        onClose={() => model?.setConfirmDeleteMember(false)}
        content={friendsManage.deleted}
        submitText={friendsManage.confirm}
      />
      <ImgModal
        open={model?.confirmRefuseMember}
        onClose={() => model?.setConfirmRefuseMember(false)}
        content={friendsManage.refused}
        submitText={friendsManage.confirm}
      />
    </MemberListContainer>
  );
};

const ScrollBox = styled.div`
  /* 스크롤바 설정*/
  &::-webkit-scrollbar {
    width: 17px;
  }

  /* 스크롤바 막대 설정*/
  &::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: #c7c7c7;
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;

    background-clip: padding-box;
    border: 6px solid transparent;
  }

  /* 스크롤바 뒷 배경 설정*/
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const MemberListContainer = styled(ScrollBox)`
  width: 100%;
  overflow-y: scroll;
`;

const MemberCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  padding: 35px 40px;
`;

const MemberRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const MemberInfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const MemberButtonWrap = styled.div`
  display: flex;
  & > span {
    width: 10px;
  }
`;

const CellLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #777;
  margin-right: 6px;
`;

const NameInfo = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
  color: #000;
  margin-right: 2px;
`;

const PositionInfo = styled.div`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.32px;
  color: #222;
  margin-top: 3px;
  margin-left: 5px;
`;

const MemberContactWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  color: #000;

  margin-bottom: 8px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 14px;
`;

const AuthorityButtonWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    height: 10px;
  }
`;

export default FriendListTable;
