import { Routes, Route } from 'react-router-dom';
import AuthGuard from 'components/Guard/AuthGuard';
import PermissionGuard from 'components/Guard/PermissionGuard';
import Main from 'pages/Main/Main';
import Works from 'pages/Works/Works';
import WorkDetail from 'pages/Works/WorkDetail';
import WorkRegist from 'pages/Works/WorkRegist';
import WorkEdit from 'pages/Works/WorkEdit';
import Resume from 'pages/Resume/Resume';
import Friends from 'pages/Friends/Friends';
import Mypage from 'pages/Mypage/Mypage';
import AdminPage from 'pages/Mypage/AdminPage';
import Sign from 'pages/Signup/Sign';
import Login from 'pages/Signup/Login';
import SearchAuth from 'pages/Signup/SearchAuth';
import ChangeAuth from 'pages/Signup/ChangeAuth';
import Error404 from 'pages/Error404/Error404';
import { AppViewModel } from 'services/AppViewModel';
import 'animate.css';

function App() {
  AppViewModel();

  return (
    <>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/works' element={<Works />} />
        <Route path='/works/:id' element={<WorkDetail />} />
        <Route
          path='/work-regist'
          element={
            <AuthGuard>
              <WorkRegist />
            </AuthGuard>
          }
        />
        <Route
          path='/work-edit/:id'
          element={
            <AuthGuard>
              <WorkEdit />
            </AuthGuard>
          }
        />
        <Route path='/resume' element={<Resume />} />
        <Route
          path='/friends'
          element={
            <AuthGuard>
              <Friends />
            </AuthGuard>
          }
        />
        <Route
          path='/mypage'
          element={
            <AuthGuard>
              <Mypage />
            </AuthGuard>
          }
        />
        <Route
          path='/adminpage'
          element={
            <PermissionGuard>
              <AdminPage />
            </PermissionGuard>
          }
        />
        <Route path='/sign' element={<Sign />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login/searchpw' element={<SearchAuth />} />
        <Route path='/login/chagepw' element={<ChangeAuth />} />
        <Route path='/404' element={<Error404 />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
