import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLogin } from 'api/auth';
import SetErrorBar from 'utils/SetErrorBar';
import { LoginPayloadProps } from 'utils/Types';
import { useLang } from 'utils/lang/useLang';

export const LoginViewModel = (
  values: any,
  setValue: (e: any, v: any) => void
) => {
  const { message } = useLang();
  const navigate = useNavigate();
  const saveId = localStorage.getItem('id');
  const [isSaveId, setIsSaveId] = useState<boolean>(false);
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);

  useEffect(() => {
    if (saveId !== null) {
      setValue('userId', saveId || '');
      setIsSaveId(true);
    }
  }, [saveId]);

  useEffect(() => {
    if (values.userId !== '' && values.userPw !== '') {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.userId, values.userPw]);

  // 로그인 핸들러
  const handleLogin = async () => {
    const payload: LoginPayloadProps = {
      user_name: values.userId,
      password: values.userPw,
    };
    const loginData = await fetchLogin(payload);

    if (loginData.status === 200) {
      const targetStorage = autoLogin ? localStorage : sessionStorage;
      targetStorage.setItem('accessToken', loginData.data.access_token);
      targetStorage.setItem('refreshToken', loginData.data.refresh_token);
      if (isSaveId) {
        localStorage.setItem('id', values.userId);
      } else {
        localStorage.removeItem('id');
      }
      navigate('/', { replace: true });
    } else {
      if (loginData.response?.data.message === 'invalid user_name') {
        SetErrorBar(message.invalidUserName);
      }
      if (loginData.response?.data.message === 'invalid password') {
        SetErrorBar(message.invalidPassword);
      }
    }
  };
  return {
    isSaveId,
    autoLogin,
    isAbledBtn,
    setIsSaveId,
    setAutoLogin,
    handleLogin,
  };
};
