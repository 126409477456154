import request from './request';

// 이력서 가져오기
export const fetchGetResume = async () => {
  try {
    const result = await request('/resume', 'get', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};

// 이력서 등록
export const fetchPostResume = async (resume_image_id: number) => {
  try {
    const result = await request('/resume', 'post', {}, { resume_image_id });

    return result;
  } catch (error: any) {
    return error;
  }
};

// 이력서 수정
export const fetchUpdateResume = async (
  fileId: number,
  resume_image_id: number
) => {
  try {
    const result = await request(
      `/resume/${fileId}`,
      'put',
      {},
      { resume_image_id }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};

// 이력서 삭제
export const fetchDeleteResume = async (pdfId: string) => {
  try {
    const result = await request(`/resume/${pdfId}`, 'delete', {}, {});

    return result;
  } catch (error: any) {
    return error;
  }
};
