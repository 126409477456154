import { forwardRef, useCallback, useRef, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// utils
import { ERROR_COLOR } from "utils/constants";
import ErrorsMessage from "components/ErrorsMessage/ErrorsMessage";

// assets
import BtnXSVG from "assets/images/btn_x.svg";
import { FileInputProps } from "utils/Types";

const FileInput3 = forwardRef<HTMLInputElement, FileInputProps>(
  (props, ref) => {
    const { idx, placeholder, accept, errors, ...rest } = props;

    const root = rootCss(props);
    const label = labelCss(props);

    const fileRef = useRef<HTMLInputElement>(null);
    const fileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (rest.value.length === 0) {
        if (fileInput.current) fileInput.current.value = "";
        if (fileRef.current) fileRef.current.value = "";
      }
      if (rest.value.length > 0) {
        if (fileRef.current) fileRef.current.value = rest.value[0]?.name;
      }
    }, [rest.value]);

    const handleChange = useCallback((e: any) => {
      if (fileRef.current) fileRef.current.value = e.target.files[0]?.name;
      rest.onChange(e.target.files);
    }, []);

    const handleRemoveFile = useCallback(() => {
      if (fileInput.current) fileInput.current.value = "";
      if (fileRef.current) fileRef.current.value = "";
    }, []);

    return (
      <div css={root}>
        <div>
          <div className="file-inner">
            <input
              type="file"
              id={`files${idx}`}
              className="files"
              ref={fileInput}
              accept={accept}
              onChange={(e: any) => handleChange(e)}
            />
            <label css={label}>
              <input
                type="text"
                id={`file-submit${idx}`}
                className="file-submit"
                ref={fileRef}
                placeholder={placeholder}
                disabled
              />
            </label>
            <button
              type="button"
              className="file-delete"
              onClick={handleRemoveFile}
            >
              <img src={BtnXSVG} alt="삭제 버튼 아이콘" />
            </button>
          </div>
          <button type="button" className="add-file">
            <label htmlFor={`files${idx}`}>파일 첨부</label>
          </button>
        </div>
        {errors && <ErrorsMessage errors={errors} name={props.name} />}
      </div>
    );
  }
);

const rootCss = (props: FileInputProps) => {
  return css`
    & > div {
      display: flex;
      width: 100%;
    }

    & .file-inner {
      width: ${props.w ? `${props.w}px` : "395px"};
      display: flex;
      justify-content: space-between;
    }

    & .files {
      display: none;
    }

    & .file-delete {
      width: 50px;
      display: flex;
      align-items: center;
      position: relative;
      left: -35px;
      margin-right: 13px;
      background: none;
      border: 0;
      font-size: 14px;
      color: #707070;
      font-weight: normal;
      cursor: pointer;

      & img {
        margin-left: 4px;
      }
    }
    & .add-file {
      width: ${props.w ? "88px" : "120px"};
      height: 65px;
      background-color: #1f1852;
      color: #fff;
      font-size: 15px;
      margin-top: 0;
      margin-left: 8px;

      & label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  `;
};

const labelCss = (props: FileInputProps) => {
  return css`
    & .file-submit {
      position: relative;
      display: flex;
      align-items: center;
      width: ${props.w ? `${props.w}px` : "395px"};
      height: 65px;
      background: transparent;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
      border: solid 1px #ddd;
      background: #f9f9f9;

      &::placeholder {
        color: #b2b2b2;
      }
    }
  `;
};

export default FileInput3;
