import { createSlice } from '@reduxjs/toolkit';

interface LangState {
  isLang: string;
}

const initialState: LangState = {
  isLang: '',
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setIsLang: (state, action) => {
      state.isLang = action.payload;
    },
  },
});

export const { setIsLang } = langSlice.actions;

export default langSlice.reducer;
