import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'layout/Layout';
import searchAuthSchma from 'utils/validation/searchAuthSchma';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { StringProps } from 'utils/Types';
import { SearchAuthViewModel } from 'services/SearchAuthViewModel';
import { useLang } from 'utils/lang/useLang';

const defaultValues: StringProps = {
  email: '',
};

const SearchAuth = () => {
  const { auth } = useLang();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<StringProps>({
    defaultValues,
    resolver: yupResolver(searchAuthSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();
  const model = SearchAuthViewModel(values);

  return (
    <Layout>
      <SearchAuthStyled>
        <div className='searchpw-inner'>
          <h2>{auth.findPassword}</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  model?.handleSearchAuth();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder={auth.email}
                    errors={errors}
                  />
                )}
              />
            </div>
            {model?.isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                {auth.findPassword}
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                {auth.findPassword}
              </Button>
            )}
          </form>
        </div>
      </SearchAuthStyled>
    </Layout>
  );
};

const SearchAuthStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 20px 0 20px;

  & .searchpw-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .mo-form-wrap {
    width: 100%;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default SearchAuth;
