import { useEffect, useMemo, useState } from 'react';
import { fetchFriendsList, fetchFriendsRequests } from 'api/friends';

export const FriendsViewModel = () => {
  const [friendsData, setFriendsData] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    handleFriendsList();
    handleFriendsRequest();
  }, []);

  // 친구 리스트
  const handleFriendsList = async () => {
    const friendListData = await fetchFriendsList();

    if (friendListData) {
      setFriendsData(friendListData.result);
    }
  };

  // 받은 요청 리스트
  const handleFriendsRequest = async () => {
    const frienndRequestData = await fetchFriendsRequests();

    if (frienndRequestData) {
      setFriendRequests(frienndRequestData.result);
    }
  };

  const isApproved: any = useMemo(() => {
    if (!friendRequests) return;
    return friendRequests?.filter((v: any) => v.is_friend === true);
  }, [friendRequests]);

  return {
    friendsData,
    friendRequests,
    isApproved,
    handleFriendsList,
    handleFriendsRequest,
  };
};
