import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from 'layout/Layout';
import Box from 'components/Box/Box';
import Pagination from 'components/Pagination/Pagination';
import SetErrorBar from 'utils/SetErrorBar';
import { WorkViewModel } from 'services/WorkViewModel';
import { useLang } from 'utils/lang/useLang';
import { useChangeLanguage } from 'hooks/useChangeLanguage';

const Works = () => {
  const lang = useLang();
  const { isLang } = useChangeLanguage();
  const navigate = useNavigate();
  const permission = useSelector((s: any) => s.auth.create_permission);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const model = WorkViewModel();

  return (
    <Layout>
      <WorksStyled>
        <div className='work-header'>
          <h3>
            {lang.common.total} {model?.totalCount}
            {isLang === 'ko' && '개'}
          </h3>
          {model?.accessToken && (
            <div className='registBtn'>
              <button
                onClick={() => {
                  if (permission) {
                    navigate(`/work-regist?type=${model?.queryString}`, {
                      state: {
                        type: model?.queryString,
                      },
                    });
                  } else {
                    SetErrorBar(lang.message.approvalRegister);
                  }
                }}
              >
                {lang.common.submit}
              </button>
            </div>
          )}
        </div>
        {isMobileWidth ? (
          <div className='contents-box'>
            {model?.resultData?.length > 0 ? (
              model?.resultData?.map((x, i) => {
                return (
                  <Box
                    key={i}
                    data={x}
                    w={'95'}
                    onClick={() =>
                      navigate(`/works/${x.id}?type=${model?.queryString}`, {
                        state: {
                          portfolioId: x.id,
                          type: model?.queryString,
                        },
                      })
                    }
                  />
                );
              })
            ) : (
              <div className='empty-content'>
                {lang.project.noProjects}
                <br />
                {lang.project.registerProject}
              </div>
            )}
            <div className='pagenation'>
              <Pagination
                page={model?.page}
                count={model?.totalPages}
                list={model?.resultData?.length}
                onChangePage={model?.setPage}
              />
            </div>
          </div>
        ) : (
          <div className='contents-box'>
            {model?.resultData?.length > 0 ? (
              model?.resultData?.map((x, i) => {
                return (
                  <Box
                    key={i}
                    data={x}
                    w={'31'}
                    onClick={() =>
                      navigate(`/works/${x.id}?type=${model?.queryString}`, {
                        state: {
                          portfolioId: x.id,
                          type: model?.queryString,
                        },
                      })
                    }
                  />
                );
              })
            ) : (
              <div className='empty-content'>
                {lang.project.noProjects}
                <br />
                {lang.project.registerProject}
              </div>
            )}
            <div className='pagenation'>
              <Pagination
                page={model?.page}
                count={model?.totalPages}
                list={model?.resultData?.length}
                onChangePage={model?.setPage}
              />
            </div>
          </div>
        )}
      </WorksStyled>
    </Layout>
  );
};

const WorksStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff;
  padding: 20px 20px 100px 20px;
  overflow-y: scroll;

  & .work-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
  }

  & .empty-content {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
  }

  & .contents-box {
    display: flex;
    flex-wrap: wrap;
  }

  & .registBtn {
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-bottom: 10px;

    button {
      cursor: pointer;
      width: 80px;
      height: 35px;
      color: #fff;
      border-radius: 4px;
      background-color: #1f1852;
    }
  }

  .pagenation {
    width: 100%;
  }
`;

export default Works;
