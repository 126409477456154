import { fetchUpdateAlert } from 'api/alerts';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsAlert } from 'store/alert';
import { setChatTitle, setIsChat } from 'store/chat';

export const AlertMessageViewModel = (alertsData: any, handleAlerts: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleAlerts();
  }, []);

  const unreadAlerts = useMemo(() => {
    if (!alertsData) return [];
    return alertsData?.filter((v: any) => v.is_readed === false);
  }, [alertsData]);

  const handleUpdateAlert = async (
    id: string,
    status: boolean,
    alertCase: string,
    data: any
  ) => {
    const updateAlertData = await fetchUpdateAlert(id);

    if (updateAlertData.status === 200) {
      if (alertCase === 'CHAT_MESSAGE') {
        dispatch(setIsChat(true));
        dispatch(setIsAlert(false));
        dispatch(setChatTitle('c'));
      }
      if (
        alertCase === 'REQUEST_FRIEND' ||
        alertCase === 'REJECT_FRIEND' ||
        alertCase === 'ACCEPT'
      ) {
        dispatch(setIsAlert(false));
        navigate('/friends');
      }
    }
  };

  return { unreadAlerts, handleUpdateAlert };
};
