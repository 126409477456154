import { fetchAlert } from 'api/alerts';
import { useEffect, useMemo, useState } from 'react';

export const HeaderViewModel = () => {
  const [alertsData, setAlertsData] = useState([]);

  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) handleAlerts();
  }, [accessToken]);

  const unreadAlerts = useMemo(() => {
    if (!alertsData) return [];
    return alertsData?.map((v: any) => v.is_readed);
  }, [alertsData]);

  const handleAlerts = async () => {
    const data = await fetchAlert();

    if (data) {
      setAlertsData(data.result);
    }
  };

  return {
    accessToken,
    unreadAlerts,
    alertsData,
    handleAlerts,
  };
};
