import { setIsLang } from 'store/lang';
import i18n from 'utils/lang/i18n.config';
import { useDispatch, useSelector } from 'react-redux';

export const useChangeLanguage = () => {
  const dispatch = useDispatch();
  const { isLang } = useSelector((s: any) => s.lang);

  const handleChangeLanguage = () => {
    if (isLang === 'en') {
      dispatch(setIsLang('ko'));
      i18n.changeLanguage('ko');
    } else {
      dispatch(setIsLang('en'));
      i18n.changeLanguage('en');
    }
  };

  return { isLang, handleChangeLanguage };
};
