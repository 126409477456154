import { useState, useEffect, useRef, forwardRef } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { InputProps } from 'utils/Types';
import ErrorsMessage from 'components/ErrorsMessage/ErrorsMessage';

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    multiline,
    useMaxLengthIndicator,
    maxLength,
    fullWidth,
    trim,
    placeholder,
    disabled,
    errors,
    ...rest
  } = props;

  const innerRef = useRef<HTMLTextAreaElement | null>(null);
  const [currentLength, setCurrentLength] = useState<number>(0);

  useEffect(() => {
    if (innerRef?.current) {
      setCurrentLength(innerRef.current.value.length);
    }
  }, [innerRef?.current]);

  const handleChange = (e: any) => {
    if (e.target.maxLength > 0) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    props.onChange && props.onChange(e);
    setCurrentLength(e.target.value.length);
  };

  const handleBlur = (e: any) => {
    props.onBlur && props.onBlur(e);
    trim && trim(props.name, e.target.value.trim());
  };

  const root = rootCss(props);
  const input = inputCss(props);
  const textarea = textareaCss(props);

  return (
    <div css={root}>
      {multiline ? (
        <textarea
          ref={(r) => {
            innerRef.current = r;
          }}
          {...rest}
          css={[textarea]}
          onChange={handleChange}
          placeholder={placeholder}
        />
      ) : (
        <input
          {...rest}
          ref={ref}
          type={type}
          css={input}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}

      {useMaxLengthIndicator && props.maxLength ? (
        <p css={maxLengthIndicator}>
          {currentLength}/{props.maxLength}
        </p>
      ) : null}
      {errors && <ErrorsMessage errors={errors} name={props.name} />}
    </div>
  );
});

const rootCss = (props: InputProps) => {
  return css`
    position: relative;
    width: ${props.w ? `${props.w}px` : '100%'};
    background-color: transparent;
  `;
};

const inputCss = (props: InputProps) => {
  return css`
    width: 100%;
    height: 65px;
    background: ${props.disabled ? '#d9d9d9' : '#fff'};
    font-size: 16px;
    color: #000;
    padding-left: 20px;
    border: solid 1px #ddd;
    outline: none;
    &::placeholder {
      color: #b2b2b2;
    }
    &:-internal-autofill-selected {
      transition: background-color 60000s;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  `;
};

const textareaCss = (props: InputProps) => {
  return css`
    width: 100%;
    height: 200px;
    font-size: 16px;
    background: #fff;
    color: #000;
    padding: 20px 0 0 20px;
    border: solid 1px #ddd;
    resize: none;

    &::placeholder {
      color: #b2b2b2;
    }
  `;
};

const maxLengthIndicator = css`
  position: absolute;
  bottom: 20px;
  right: 15px;
  font-size: 13px;
  color: #b2b2b2;
  line-height: 1;
`;

export default Input;
