import styled from '@emotion/styled';
import Button from 'components/Button/Button';
import Modal, { ModalButtonWrap, ModalContainer } from 'components/Modal/Modal';
import IcDelSVG from 'assets/images/ic-del.svg';
import { IImgModalProps } from 'utils/Types';

const ImgModal = (props: IImgModalProps) => {
  return (
    <Modal {...props}>
      <ModalContainer>
        {props.imgUrl ? (
          <IconContainer onClick={props.onClose}>
            <Icon src={IcDelSVG} />
          </IconContainer>
        ) : null}
        {props.imgUrl ? (
          <ImageContainer>
            <Image src={IcDelSVG} />
          </ImageContainer>
        ) : null}
        <ModalTitle>{props?.title}</ModalTitle>
        {props.content && (
          <ModalText>
            <span style={{ color: '#258fff' }}>{props?.email}</span>
            {props?.content}
          </ModalText>
        )}
        <ModalRedText>{props?.redContent}</ModalRedText>
        <ModalButtonWrap>
          {props.onSubmit ? (
            <Button
              variant=''
              preset={0}
              type='button'
              w={'100'}
              h={'48'}
              fSize={'14'}
              disabled
              onClick={props.onClose}
            >
              취소
            </Button>
          ) : null}
          {props.submitText && (
            <Button
              variant=''
              preset={0}
              type='button'
              w={'100'}
              h={'48'}
              fSize={'14'}
              onClick={props?.onSubmit ? props.onSubmit : props.onClose}
            >
              {props?.submitText || '확인'}
            </Button>
          )}
        </ModalButtonWrap>
      </ModalContainer>
    </Modal>
  );
};

const MbModalText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 12px 0;
  & > div {
    max-width: 188px;
    font-family: SourceHanSansKR;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.28px;
    text-align: center;
    color: #000;
    white-space: pre-line;
  }
`;

const MbModalTitle = styled.div``;

const ModalText = styled.div`
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000;
  text-align: center;
  margin-top: 20px;
  white-space: pre;
`;

const ModalTitle = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000;
  text-align: center;
  margin-top: 20px;
  white-space: pre;
`;

const MbModalRedText = styled.div`
  width: 242px;
  height: 17px;
  font-family: SourceHanSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.24px;
  text-align: center;
  color: #ef0000;
`;

const ModalRedText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.32px;
  text-align: center;
  color: #ef0000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  white-space: pre;
`;

const BlueText = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.32px;
  color: #258fff;
  text-align: center;
  margin-top: 20px;
  white-space: pre;
`;

const IconContainer = styled.div`
  text-align: right;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  text-align: center;
`;
const MbImageContainer = styled.div``;

const MbImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

const Image = styled.img``;

const MbModalContainer = styled.div`
  width: 300px;
  min-height: 230px;
  margin: 11px 30px 32px 30px;
  padding: 20px 20px 40px 29px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export default ImgModal;
