import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUpdatePortfolio } from 'api/portfolio';
import { fetchFileUpload } from 'api/auth';

interface Props {
  control: any;
  values: any;
  setValue: (e: any, v: any) => void;
  setFocus: (e: any) => void;
}

export const WorkEditViewModel = ({
  control,
  values,
  setValue,
  setFocus,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [img, setImg] = useState<any>([]);
  const [skillArr, setSkillArr] = useState<string[]>();
  const [fileInfoData, setFileInfoData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    fields: workFields,
    append: workAppend,
    remove: workRemove,
  } = useFieldArray({
    control,
    name: 'subWorkInput',
  });

  const handleAddWork = useCallback(() => {
    workAppend({
      sub_title: '',
      sub_content: '',
      sub_picture: [],
    });
  }, [workAppend]);

  useEffect(() => {
    if (location?.state?.arrayData?.length > 1) {
      for (let i = 1; i < location?.state?.arrayData?.length; i++) {
        workAppend({
          sub_title: '',
          sub_content: '',
          sub_picture: [],
        });
      }
    }

    setTimeout(() => {
      if (location?.state?.arrayData) {
        setFocus('title');
      }
    }, 0);
  }, [location?.state?.arrayData]);

  useEffect(() => {
    for (let i = 0; i < location?.state?.arrayData?.length; i++) {
      setValue(
        `subWorkInput.${i}.sub_title`,
        location?.state?.arrayData[i]?.sub_title
      );
      setValue(
        `subWorkInput.${i}.sub_content`,
        location?.state?.arrayData[i]?.sub_content
      );
      let arrayFile: any = [];
      if (location?.state?.arrayData[i]?.sub_picture) {
        arrayFile = [
          {
            name: `${location?.state?.arrayData[i]?.sub_picture?.file_name}`,
            id: location?.state?.arrayData[i]?.sub_picture?.id,
            size: location?.state?.arrayData[i]?.sub_picture?.size,
          },
        ];
        setValue(`subWorkInput.${i}.sub_picture`, arrayFile);
      }
    }
  }, [location?.state?.arrayData]);

  useEffect(() => {
    setFileInfoData(location?.state?.data?.attach_ments_info);
    setValue('title', location?.state?.data?.raw.title);
    setValue('position', location?.state?.data?.raw.position);
    setValue('skill', location?.state?.data?.raw.skils);
    setValue(
      'startdate',
      new Date(
        dayjs(location?.state?.data?.raw?.started_at).format('YYYY-MM-DD')
      )
    );
    setValue(
      'enddate',
      new Date(dayjs(location?.state?.data?.raw.end_at).format('YYYY-MM-DD'))
    );
    setValue('outline_content', location?.state?.data?.raw.outline_content);
  }, []);

  useEffect(() => {
    const arr = values?.skill?.split(',');
    setSkillArr(arr);
    setImg(values?.files);
  }, [values.skill, values.files]);

  const handleExceptFile = (exceptId: number) => {
    const filteredData = fileInfoData.filter((v: any) => {
      return v.id !== exceptId;
    });

    setFileInfoData(filteredData);
  };

  // 포트폴리오 수정 핸들러
  const handleSubmitForm = async () => {
    setIsLoading(true);
    let attachment: any = [];
    for (let i = 0; i < fileInfoData?.length; i++) {
      attachment.push(fileInfoData[i]?.id);
    }

    if (img) {
      for (let i = 0; i < img.length; i++) {
        const file = img[i][0];
        const fileUploadData = await fetchFileUpload(file);
        attachment.push(fileUploadData.data.result.id);
      }
    }

    let payload: any = {
      type: location?.state.type,
      title: values.title,
      position: values.position,
      skils: values.skill,
      started_at: values.startdate,
      end_at: values.enddate,
      outline_content: values.outline_content,
    };

    for (let i = 0; i < values?.subWorkInput?.length; i++) {
      let attachment2: any = [];

      if (values?.subWorkInput[i]?.sub_picture?.length > 0) {
        if (
          values?.subWorkInput[i]?.sub_picture[0]?.name ===
          location?.state?.arrayData[i]?.sub_picture?.file_name
        ) {
          attachment2.push(location?.state?.arrayData[i]?.sub_picture?.id);
        } else {
          const file = values?.subWorkInput[i]?.sub_picture[0];
          const fileUploadData = await fetchFileUpload(file);
          attachment2.push(fileUploadData.data.result.id);
        }
      }
      if (values?.subWorkInput[i]) {
        payload = {
          ...payload,
          [`sub_title_${i + 1}`]: values?.subWorkInput[i]?.sub_title,
          [`sub_content_${i + 1}`]: values?.subWorkInput[i]?.sub_content,
        };
        if (attachment2.length > 0) {
          payload = {
            ...payload,
            [`sub_picture_${i + 1}`]: attachment2,
          };
        }
      }
    }

    if (attachment) {
      payload = {
        ...payload,
        attachments: attachment,
      };
    }

    const updateData = await fetchUpdatePortfolio(
      location?.state.data.raw.id,
      payload
    );

    if (updateData.status === 200) {
      setIsLoading(false);
      navigate(`/works?type=${location?.state.data.raw.type}`, {
        state: {
          type: location?.state.data.raw.type,
        },
      });
    } else {
      setIsLoading(false);
    }
  };

  return {
    img,
    fileInfoData,
    workFields,
    isLoading,
    workRemove,
    handleAddWork,
    handleExceptFile,
    handleSubmitForm,
  };
};
