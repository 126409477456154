import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { ChildrenProps } from 'utils/Types';
import Header from './Header';
import SideBar from './SideBar';

const Layout = ({ children }: ChildrenProps) => {
  const { isMobileWidth } = useSelector((s: any) => s.ui);

  return (
    <>
      <Header />
      <Container>
        {!isMobileWidth && <SideBar />}
        {children}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
`;

export default Layout;
