export const useSignUpEssentialDataCheck = (values: any) => {
  return (
    values.email !== '' &&
    values.password !== '' &&
    values.passwordCheck !== '' &&
    values.name !== '' &&
    values.userPhone1.value !== '' &&
    values.userPhone2 !== '' &&
    values.position.value !== ''
  );
};

export const useMypageEssentialDataCheck = (values: any) => {
  return (
    values.email !== '' &&
    values.name !== '' &&
    values.userPhone1.value !== '' &&
    values.userPhone2 !== '' &&
    values.position.value !== ''
  );
};
